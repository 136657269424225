import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(0.3, 0.3),
    marginTop: "-1px",
    backgroundColor: "rgb(21,21,21)",
    display:'flex',
    justifyContent:'flex-end',
    alignItems: 'center',
  },
  footerButton: {
    fontSize: '14px', 
    fontFamily: 'Outfit-Light',
    color: '#FFFFFF',
    marginRight: '25px', 
    textTransform: 'none'
    
  }
}));

export default function Footer({ handlePrivacyClick }) {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
        <Button onClick={handlePrivacyClick} className={classes.footerButton}>
          Privacy policy
        </Button>
    </footer>
  );
}
