import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ResponsivePie } from "@nivo/pie";
import MyResponsivePie from "./PieChart";
import MyResponsivePie2 from "./PieChar2";
import MyResponsivePie3 from "./PieChart3";
import MyResponsivePie4 from "./PieChar4";
import MyResponsiveRadar from "./RadarChart";
import  { useState, useEffect } from "react";
import Modal from 'react-modal';
import LoupeIcon from "@material-ui/icons/Loupe";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from '@material-ui/core/Tooltip';

const videos = [
  './Aboutme/ailoop2min.mp4',
  //'./Aboutme/red.mp4',

];

const FlexBreak = () => (
  <div style={{ flexBasis: "100%", height: "0" }} />
);

function RandomVideo({ style }) {
  const [currentVideo, setCurrentVideo] = useState(videos[Math.floor(Math.random() * videos.length)]);

  useEffect(() => {
    setCurrentVideo(videos[Math.floor(Math.random() * videos.length)]);
  }, []);

  return (
    <video 
      src={currentVideo} 
      alt="Fungi Studies" 
      style={style} 
      autoPlay 
      loop 
      muted
    />
  );
}


const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "0.5rem",
    paddingBox: "2rem",
    flexDirection: "column",
    borderRadius: "7px",

  },
  header: {
    Color: "#ff69b4",
    display: "flex",
    alignItems: "center",
    padding: "0.5rem",

    position: "relative" 
  },
  spacer: {
    Color: "#ff69b4",
    flexGrow: 1
    
  },
  body: {
    Color: "#ff69b4",
    padding: "0.5rem",
    flexGrow: 1
  },


  closeButton: {
    position: "absolute", 
    top: "0rem", 
    right: "0rem",
  },

  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  infoButton: {
    position: "absolute",
    top: "0rem",
    right: "3rem", 
    zIndex: 1,// Adjust the position as necessary
  },

});


const data = [
  {
    "id": "Unreal engine 5",
    "label": "Unreal engine 5",
    "value": 148,
    "color": "hsl(61, 70%, 50%)"
  },
  {
    "id": "Keyshot",
    "label": "Keyshot",
    "value": 73,
    "color": "hsl(203, 70%, 50%)"
  },
  {
    "id": "Fusion 360",
    "label": "Fusion 360",
    "value": 497,
    "color": "hsl(82, 70%, 50%)"
  },
  {
    "id": "Blender",
    "label": "Blender",
    "value": 501,
    "color": "hsl(325, 70%, 50%)"
  },
  {
    "id": "FDM Printing",
    "label": "FDM Printing",
    "value": 577,
    "color": "hsl(221, 70%, 50%)"
  }
];



//widget title
const Project = <div className="font-link" >
  <h4>Styl. att. 1</h4>
</div>;


const Projecta = <div className="font-link">
  <h4 className="PlayfairDisplay-ExtraBold "  style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",}}>Fungi Studies</h4>
</div>;

const Projectb = <div className="font-link">
  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",}}>Remember Me</h4>
</div>;

const Projectc = <div className="font-link">
<h4 style={{ marginTop: 0,marginBottom: -10}}></h4>
</div>;

const Projectd = <div className="font-link">
  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",}}></h4>
</div>;

const Projecte = <div className="font-link">
  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 25,marginBottom: 25, fontSize: "30px",}}></h4>
</div>;
const Projectf = <div className="font-link">
<h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 25,marginBottom: 25, fontSize: "30px",}}></h4>
</div>;

const Projectg = <div className="font-link">
  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",}}>Rocking Horse</h4>
</div>;

const Projecth = <div className="font-link">
  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",}}>Layering</h4>
</div>;

const Projecti = <div className="font-link">

  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",}}>Opener</h4>
</div>;

const Projectj = <div className="font-link">
   <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 25,marginBottom: 25, fontSize: "30px",}}></h4>
</div>;

const Projectk = <div className="font-link">
   <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",}}>Fungi Bottle</h4>
</div>;

const Projectl = <div className="font-link">

  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",}}>Scoby Samples</h4>
</div>;

const Projectm = <div className="font-link">

  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",color: "#ececec"}}>My Workflow</h4>
</div>;


const Projectn = <div className="font-link">

  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",color: "#ececec"}}>Me/Contact</h4>
</div>;

const Projecto = <div className="font-link">

  <h4 className="PlayfairDisplay-ExtraBold " style={{ marginTop: 30,marginBottom: 25, fontSize: "30px",color: "#ececec"}}>Datenschutzerklärung</h4>
</div>;

const widgetInfo = {
  b: {
    semester: "Semester for B",
    year: "Year for B"
  },
  h: {
    semester: "Semester for H",
    year: "Year for H"
  }
  // Add more widgets info as needed
};

//widget Content
const Content1 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>




<img src="./schicht/layerchart (10).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} />    


<img src="./schicht/layerchart (2).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 


<img src="./schicht/layerchart (3).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 


<img src="./schicht/layerchart (4).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 


<img src="./schicht/layerchart (1).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 


<img src="./schicht/layerchart (6).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 


<img src="./schicht/layerchart (7).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 


<img src="./schicht/layerchart (8).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 


<img src="./schicht/layerchart (9).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 


<img src="./schicht/layerchart (5).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 

<img src="./schicht/layerchart (11).png" alt="Fungi Studies" style={{ 
   flex: "1 1 auto", 
  maxWidth: "320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} /> 

<img src="./schicht/layerchart (12).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
 maxWidth: "320px", 
 minWidth: "200px", 
 borderRadius: "8px", 
 width: "calc(15% - 8px)", 
 margin: "4px"
}} /> 



<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "488px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  border: "1px solid black", 
borderRadius: "8px", 
margin: "24px 4px 4px 4px",
padding: "4px",
paddingLeft: "18px",
paddingRight: "18px",
}}>
<h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px" ,marginBottom: "0px"}}>A basics exercise</h4>
 

 <p className="Outfit-Light" >
 "Layers" was an experimental design exercise in the 2nd semester, 
 in which we were supposed to find or create material layering or coatings that we found formally aesthetically interesting, for 
 example for later use in product designs. <br />
 <br />
 I used this exercise to get to grips with the most basic principles 
 of documentary work and the purposeful, reproducible 
 creation of variance. The tables document these approaches. 
 The "layers" are divided from the lowest and first cast (1) 
to the highest (3) cast.  
 
    </p></div>
</div>;





const Content2 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>
<img src="./Remember/0050 (2).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "1500px", 
  minWidth: "300px", 
  borderRadius: "8px", 
  width: "calc(100% - 8px)", 
  margin: "4px"
}} /> 

<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "450px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  width: "calc(33% - 8px)", 
  marginTop:"24px"
  }}>
  <h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', 
  marginTop: "20px",
  marginBottom: "0px",
  margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",}}>What is nitinol?</h4>

  <p className="Outfit-Light" style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}> 
      Nitinol was developed in 1958 at the Naval
      Ordnance Laboratory by William J.
      Buehler and Frederick Wang.
      The name is derived from the
      nickel and titanium, and from 
      the initials of the Naval Ordnance Laboratory. Nitinol is a
      shape memory alloy, which consists of approx.
      55% nickel, and the other part
      of titanium.

</p>
</div>




<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "430px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  width: "calc(33% - 8px)", 
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "24px"
    }}>

  <p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "0px" ,marginLeft: "0px"}}>  
  Normal nitinol 
  has the ability to "remember" its shape,
  if its temperature is between -20 °C and 110
  °C (depending on the percentage of the
  alloy).
  The original shape of the material
  can be changed by fixating it in the
  the desired "original shape" and heating it to over 500 °C.
</p>
</div>


<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "430px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  width: "calc(33% - 8px)", 
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "24px"
  }}>

<p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "0px" ,marginLeft: "0px"}} >
Nitinol is used to produce surgical tools, 
stents or even inflatable, woven tires for aerospace 
concepts that need a long lifespan. 
Unfortunately, the manufacturing process
of nitinol is very complex because the alloy must 
be produced in a vacuum, which causes the high price.

</p>
</div>


<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "450px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  }}>
  <h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "16px" ,marginBottom: "20px"}}>About "Remember me"</h4>

<p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "0px" ,marginLeft: "0px"}} >
For this project, I had the intent to use as little
of the material as possible to create a product
concept that actually solves a problem. The problem I 
focused on was actually the increasing duration of drought periods.
Due to climate change in Europe and the results for European woods.
The result of my design process was an (almost) completely autonomous 
water collector that catches water when it is
raining and dispenses it automatically over a long
period of time. This is achieved by using the 
sunlight and warmth produced by it to open and close the nitinol vent. 

</p> </div>

<img src="./Remember/für details (2 von 5).png" alt="Fungi Studies" style={{ 
  flex: "1 0 auto", 
  maxWidth: "954x", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(100% - 8px)", 
  margin: "4px"
}} /> 


<img src="./Remember/für details (1 von 5).png" alt="Fungi Studies" style={{ 
  flex: "1 0 auto", 
  maxWidth: "954x", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(100% - 8px)", 
  margin: "4px"
}} /> 


<img src="./Remember/für details (4 von 5).png" alt="Fungi Studies" style={{ 
  flex: "1 0 auto", 
  maxWidth: "954x", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(100% - 8px)", 
  margin: "4px"
}} /> 

<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "450px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  width: "calc(33% - 8px)", 
  marginTop:"24px"
  }}>
  <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>An automatic watering system </h4>
  
  
   <p className="Outfit-Light" style={{ margin: "4px",
   marginLeft: "24px",
   marginRight: "24px",
   marginTop: "12px",}}>  
   I started out with a variety of ideas. Initially, I intended to 
   build a big collector that would be placed on the ground to 
   collect water. After some consultations, I chose to concentrate 
   on an iteration that is actually hanging in the trees to collect water. 
   However, the principle of the collector is basically the same for both 
   of the concepts, so I consider the hanging one to be a general concept 
   explanation for both concepts.
   </p></div>


   
<img src="./Remember/FreigestelltebilderfürRememberme (2).png" alt="Fungi Studies" style={{ 
  flex: "1 0 auto", 
  maxWidth: "954x", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(100% - 8px)", 
  margin: "4px",
  marginTop: "24px"
}} /> 

<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "430px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  width: "calc(33% - 8px)", 
  marginTop:"24px"
  }}>
  <h4 className="Outfit-Medium" 
  style={{ color: 'rgb(40, 40, 40)', 
  marginTop: "20px",
  marginBottom: "0px",
  margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",}}>Prototyping</h4>
  
  
  <p className="Outfit-Light" style={{ margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginTop: "12px",}}>  
  The video below shows the complete prototyping process, 
     from sketches, over paper prototypes to molded lenses made 
     from epoxy resin. However, the most important steps are 
     documented again below, for better understanding.
   </p></div>


   
<video
src="./Remember/Komp 1.mp4"
alt="Aufbau Animation komplett"
style={{
  flex: "1 1 auto",
  maxWidth: "1500px",
  minWidth: "310px",
  borderRadius: "8px",
  width: "calc(100% - 8px)",
  margin: "4px",
  marginTop:"24px"
}}
controls
/>  




 
<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "460px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  width: "calc(33% - 8px)", 
  marginTop:"24px"
  }}>
  <h4 className="Outfit-Medium" 
  style={{ color: 'rgb(40, 40, 40)', 
  marginTop: "20px",
  marginBottom: "0px",
  margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",}}>Vent prototypes </h4>
  
  
  <p className="Outfit-Light" style={{ margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginTop: "12px",}}>  
  The vent iterations show the simple mechanism that enables 
  the autonomous functioning of the system.  A feather steel spring 
  is pushing down a nitinol spring underneath or above it. Both springs 
  are positioned inside a cylinder, which is closed with a round 
  converging lens. Between the springs, a plate is connected to a plug, 
  which keeps the vent closed or open, by a thin rod. If sunlight shines 
  onto the lens, the light is focused onto the springs, which causes the 
  nitinol spring to expand. As aresult, the vent is opened, and water is 
  flowing out of it. Some of the water floats down to the spring, which causes 
  her to cool down and shrink. This causes the vent to close itself 
  automatically. Once the sunlight reheats the nitinol again, the 
  process restarts.
   </p></div>
  

   
   <img src="./Remember/für details (3 von 5).png" alt="Fungi Studies" style={{ 
    flex: "1 0 auto", 
    maxWidth: "954x", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(100% - 8px)", 
    margin: "4px"
  }} /> 
  
  
  <img src="./Remember/vent1.jpg" alt="Fungi Studies" style={{ 
    flex: "1 0 auto", 
    maxWidth: "954x", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(50% - 8px)", 
    margin: "4px"
  }} /> 
    
  <img src="./Remember/vent2.jpg" alt="Fungi Studies" style={{ 
    flex: "1 0 auto", 
    maxWidth: "954x", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(50% - 8px)", 
    margin: "4px"
  }} /> 

 
  <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "430px", 
    minWidth: "200px", 
    flexDirection: "column", 
    alignItems: "flex-start",  
    width: "calc(33% - 8px)", 
      marginTop:"24px"
      }}>
      <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>Final prototype </h4>
    
    
      <p className="Outfit-Light" style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}>  
      The video below shows my functioning prototype. 
      The final design aims to catch as much sunlight as 
      possible from below the water collector. Therefore, it extends 
      from the body of the vent. In general, a longer period between the opening 
      of the vent and the closing by the floating water needs to be achieved 
      in the future, but the prototype proved my concept at least.
     </p></div>
     
     <video
src="./Remember/Ventil Video_1.mp4"
alt="Aufbau Animation komplett"
style={{
  flex: "1 1 auto",
  maxWidth: "1500px",
  minWidth: "310px",
  borderRadius: "8px",
  width: "calc(100% - 8px)",
  margin: "4px",
  marginTop:"24px"
}}
controls
/> 


<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "430px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  width: "calc(33% - 8px)", 
  marginTop:"24px"
  }}>
  <h4 className="Outfit-Medium" 
  style={{ color: 'rgb(40, 40, 40)', 
  marginTop: "20px",
  marginBottom: "0px",
  margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",}}>Water container </h4>
  
  
  <p className="Outfit-Light" style={{ margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginTop: "12px",}}>  
  The containers for the water collection process aim to collect a lot of water 
  if they are empty and lose as little water as possible, if they are full, 
  to condensation. By increasing the weight that is carried by the containers 
  if rain is collected inside of them, 
  their shape is shifting from open and almost flat to a cocoon-like shape. 
  The models below show my first attempts to implement this functionality 
  by using nitinol wire.</p></div>


   <img src="./Remember/für details (5 von 5).png" alt="Fungi Studies" style={{ 
    flex: "1 0 auto", 
    maxWidth: "954x", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(100% - 8px)", 
    margin: "4px"
  }} /> 


  
  <img src="./Remember/Behälter try1.jpg" alt="Fungi Studies" style={{ 
    flex: "1 0 auto", 
    maxWidth: "954x", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(50% - 8px)", 
    margin: "4px"
  }} /> 
    
  <img src="./Remember/Behälter try2.jpg" alt="Fungi Studies" style={{ 
    flex: "1 0 auto", 
    maxWidth: "954x", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(50% - 8px)", 
    margin: "4px"
  }} /> 


  <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "430px", 
    minWidth: "200px", 
    flexDirection: "column", 
    alignItems: "flex-start",  
    width: "calc(33% - 8px)", 
      marginTop:"24px"
      }}>

    <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>The complete prototype </h4>
    
    
      <p className="Outfit-Light" style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}>  
      After the first prototypes didn't work, the final water collector 
      concept did by using tiny amounts of nitinol. This is achieved by 
      using the extreme flexibility of small nitinol wires, which are 
      positioned in the holding and flexing mechanism of the container, 
      as seen in the pictures below. 
     </p></div>

     <FlexBreak />


  <img src="./Remember/final hanger.jpg" alt="Fungi Studies" style={{ 
    flex: "1 0 auto", 
    maxWidth: "954x", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(50% - 8px)", 
    margin: "4px",
    marginTop: "24px"
  }} /> 

  <img src="./Remember/Modell von Oben.jpg" alt="Fungi Studies" style={{ 
    flex: "1 0 auto", 
    maxWidth: "954x", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(50% - 8px)", 
    margin: "4px",
    marginTop: "24px"
  }} /> 

   
  <img src="./Remember/complete object.jpg" alt="Fungi Studies" style={{ 
    flex: "1 0 auto", 
    maxWidth: "1500x", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(100% - 8px)", 
    margin: "4px"
  }} /> 

     <video
src="./Remember/Wasserbehälter video.mp4"
alt="Aufbau Animation komplett"
style={{
  flex: "1 1 auto",
  maxWidth: "1500px",
  minWidth: "310px",
  borderRadius: "8px",
  width: "calc(100% - 8px)",
  margin: "4px",
}}
controls
/> 
    

    <div style={{  
      display: "flex",
      flex: "1 1 auto",  
      maxWidth: "430px", 
      minWidth: "200px", 
      flexDirection: "column", 
      alignItems: "flex-start",  
      width: "calc(33% - 8px)", 
      marginTop:"24px"
      }}>
      <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>Product animation 01 </h4>
      
      
      <p className="Outfit-Light" style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}>  
      The video shows the complete construction of the concept in the 
      state it was in when finishing the project for presentation. 
      For optimization, the vent mechanism would need to be refined, 
      and the water container should be produced from much fewer parts than 
      it is right now. But in general, the concept could be used to automated 
      water collection and dispense in many cases, reducing the need for individual 
      people to take care of watering in gardens or plantations, at least partially, 
      without having to use high-tech equipment that uses even more rare and 
      expensive materials and needs electricity to work.
       </p></div>

       <video
       src="./Remember/Aufbau Animation komplett.mp4"
       alt="Aufbau Animation komplett"
       style={{
         flex: "1 1 auto",
         maxWidth: "1500px",
         minWidth: "310px",
         borderRadius: "8px",
         width: "calc(100% - 8px)",
         margin: "4px",
         marginTop:"24px"
       }}
       controls
       /> 
</div>;





const Content3 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>
<img src="./schaukelpferd/unten.jpg" alt="Fungi Studies" style={{ 
  flex: "1 0 auto", 
  maxWidth: "954x", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(100% - 8px)", 
  margin: "4px"
}} /> 

<img src="./schaukelpferd/Seite 1.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "487px", 
  minWidth: "300px",  
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px"
}} /> 

<img src="./schaukelpferd/Seite 2.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "487px", 
  minWidth: "200px",  
 
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px"
}} /> 

<img src="./schaukelpferd/Seite 3.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "487px", 
  minWidth: "280px",  
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px"
}} /> 

<img src="./schaukelpferd/Seite 4.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "487px", 
  minWidth: "280px", 
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px"
}} /> 


 <div style={{  
  display: "flex",
  flex: "1 0 auto",  
  maxWidth: "487px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  width: "calc(33% - 8px)",
  marginTop:"24px"

  }}>


  <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>An ergonomics study</h4>


      <p className="Outfit-Light" style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}>  
        The rocking horse for grownups was a project we did in  
        our first ergonomics course in the third semester. 
        I had the intent to create something comfortable that still has an uncommon 
      
   </p></div>



   <div style={{  
    display: "flex",
    flex: "1 0 auto",  
    maxWidth: "487px", 
    minWidth: "300px", 
    flexDirection: "column", 
    alignItems: "flex-start",   
    width: "calc(33% - 8px)", 
    marginTop: "24px",
  
    }}>

    <p className="Outfit-Light" style={{
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",}} >  
    design compared to regular rocking horses. 
    The used data is documented in the charts further 
    down in this widget. I took the needed data from my "Ergonomische Datensammlung". 
    I calculated the size of the rocking horse depending on my 
    own height of around 197 cm.
     </p></div>
  

       
     <img src="./schaukelpferd/RedSketch.png" alt="Fungi Studies" style={{ 
      flex: "1 1 auto", 
      maxWidth: "1250x", 
      minWidth: "300px",
      borderRadius: "8px", 
      width: "calc(50% - 8px)", 
      margin: "4px",
      marginTop: "24px",
    }} /> 



  <img src="./schaukelpferd/transparenter Schaukel dude.png" alt="Fungi Studies" style={{ 
    flex: "1 1 auto", 
    maxWidth: "850px", 
    minWidth: "280px", 
    borderRadius: "8px", 
    width: "calc(50% - 8px)", 
    margin: "4px"
  }} /> 

  


  <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "487px", 
    minWidth: "300px", 
    flexDirection: "column", 
    alignItems: "flex-start",   
    width: "calc(33% - 8px)", 
    marginTop: "24px",
  
    }}>


    

    <h4 className="Outfit-Medium" 
    style={{ color: 'rgb(40, 40, 40)', 
    marginTop: "20px",
    marginBottom: "0px",
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",}}>The relevant data</h4>


    <p className="Outfit-Light" style={{ margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "12px",}}>  
    The data inside the chats shows the values that I 
    considered to be the most relevant for the design. 
    I applied the comfortable angles to the sketch above so 
    that I could see if the shape I created was suitable for 
    the given ergonomic data. 
     </p></div>


  <img src="./schaukelpferd/tabwinkel 11pt (2).png" alt="Fungi Studies" style={{ 
    flex: "1 1 auto", 
    maxWidth: "487px", 
    minWidth: "300px", 
    borderRadius: "8px", 
    width: "calc(33% - 8px)", 
    margin: "4px",
    marginTop: "24px",

  }} /> 

  <img src="./schaukelpferd/tabellen perc 11pt (2).png" alt="Fungi Studies" style={{ 
    flex: "1 1 auto", 
    maxWidth: "487px", 
    minWidth: "300px",
    borderRadius: "8px", 
    width: "calc(33% - 8px)", 
    margin: "4px",
    marginTop: "24px",

  }} /> 

  <img src="./schaukelpferd/transparentmitmaßref300 (3).png" alt="Fungi Studies" style={{ 
    flex: "1 1 auto", 
    maxWidth: "850px", 
    minWidth: "300px",
    borderRadius: "8px", 
    width: "calc(33% - 8px)", 
    margin: "4px"
  }} /> 





  <div style={{ 
    flex: "1 1 auto", 
    maxWidth: "850px", 
    minWidth: "280px",
    borderRadius: "8px", 
    width: "calc(66% - 8px)", 
    margin: "4px",
    marginTop: "24px",

  }} /> 

</div>;

const Content4 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "Left", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>
<img src="./wrk/Icon mit Schrift.png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "200px", 
  minWidth: "125px", 
  borderRadius: "8px", 
  width: "calc(25% - 8px)", 
  margin: "4px",
  marginTop: -37,
  marginBottom: 25,
  marginLeft: -30
}} /> 

<FlexBreak />

<img src="./wrk/Komposition_Hocker.png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "1250px", 
  minWidth: "300px", 
  borderRadius: "8px", 
  width: "calc(66% - 8px)", 
  margin: "4px, 4px, 4px, 4px",
  //marginBottom: "24px",


}} /> 



<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "487px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(66% - 8px)", 
  marginTop:"24px"
      }}>
      <h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}
      >About washi ramie kumiko</h4>

<p className="Outfit-Light" style={{ margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginTop: "12px",}}>   
  The starting point for the project "Washi Rami kumiko" 
  were the two materials washi and ramie and a Japanese 
  woodworking technique called kumiko. The materials and 
  woodworking techniques are deeply rooted in Japanese 
  and Asian culture. <br/ > 
  <br/ > 

  The starting point was the task of transferring 
  the materials and craft techniques to the European 
  cultural space.  <br/ > 
  <br/ > 
  
  I had the intent to design a modular furniture 
  concept focused on the kumiko aspect of the project 
  and on bringing the woodworking technique and effort 
  connected to it closer to people that consume products 
  originating from this craft. The project documented here 
  focuses on only one possible design that originated from 
  the modules, as an example. 
  </p> </div>

<FlexBreak />

   <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "440px", 
    minWidth: "200px", 
    flexDirection: "column", 
    alignItems: "flex-start",   
    width: "calc(33% - 8px)", 
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "16px"
    }}>
  
  <p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "0px" ,marginLeft: "0px"}}>   
  Washi is a paper that is produced from mulberry fibers and has been used 
  in Japanese craftsmanship for centuries. It's mostly known for 
  its use in shoji doors (traditional Japanese sliding doors) or lanterns.
     </p></div>


     
   <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "440px", 
    minWidth: "200px", 
    flexDirection: "column", 
    alignItems: "flex-start",   
    width: "calc(33% - 8px)", 
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "16px"
    }}>
  
  <p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "0px" ,marginLeft: "0px"}}>  
    Ramie fiber, also called china linen or grass linen, 
    is a fabric that strongly resembles linen in terms 
    of materiality and is obtained from ramie plants,  
    a plant belonging to the nettle family.
     </p></div>


        
   <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "440px", 
    minWidth: "200px", 
    flexDirection: "column", 
    alignItems: "flex-start",   
    width: "calc(33% - 8px)", 
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "16px"
    }}>
  
  <p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "0px" ,marginLeft: "0px"}}>  
  Kumiko or "kumiko zaiku" is a traditional woodworking 
  technique from Asia that is based on the principle 
  of sticking together wooden patterns without using any glue.
     </p></div>
<FlexBreak />

    <div style={{  
      display: "flex",
      flex: "1 1 auto",  
      maxWidth: "487px", 
      minWidth: "300px", 
      flexDirection: "column", 
      alignItems: "flex-start",   
      width: "calc(33% - 8px)", 
      marginTop:"24px"
      }}>
      <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}
      >Prototyping</h4>

      <p className="Outfit-Light" style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}>  
      In the beginning, I decided to use a simple 
      geometric form language, as it has been common 
      in Japan for centuries. The prototypes are made on a 
      small scale (4 cm to 10 cm), which made it possible for 
      me to test numerous variations in three-dimensional space. <br />
      <br />
      I used mat board for the models. 
      Additionally, I used a small roll of fiber fleece 
      tape to better represent the shape of the cushions. 
       </p></div>

       <img src="./wrk/mcomp.jpg" alt="Fungi Studies" style={{ 
       flex: "1 1 auto", 
       maxWidth: "1000px", 
       minWidth: "200px", 
       borderRadius: "8px", 
       width: "calc(66% - 8px)", 
       margin: "4px",
       marginTop:"24px"

     }} /> 
  
     <FlexBreak/>
     <img src="./wrk/m1.jpg" alt="Fungi Studies" style={{ 
      flex: "1 1 auto", 
     maxWidth: "340px", 
     minWidth: "200px", 
     borderRadius: "8px", 
     width: "calc(15% - 8px)", 
     margin: "4px"
   }} /> 

   
   <img src="./wrk/m2.jpg" alt="Fungi Studies" style={{ 
    flex: "1 1 auto", 
   maxWidth: "340px", 
   minWidth: "200px", 
   borderRadius: "8px", 
   width: "calc(15% - 8px)", 
   margin: "4px"
 }} /> 

 
 <img src="./wrk/m3.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
 maxWidth: "340px", 
 minWidth: "200px", 
 borderRadius: "8px", 
 width: "calc(15% - 8px)", 
 margin: "4px"
}} /> 

<img src="./wrk/m4.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
 maxWidth: "340px", 
 minWidth: "200px", 
 borderRadius: "8px", 
 width: "calc(15% - 8px)", 
 margin: "4px"
}} /> 


<img src="./wrk/m5.jpg" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
maxWidth: "340px", 
minWidth: "200px", 
borderRadius: "8px", 
width: "calc(15% - 8px)", 
margin: "4px"
}} /> 


<img src="./wrk/m6.jpg" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
maxWidth: "340px", 
minWidth: "200px", 
borderRadius: "8px", 
width: "calc(15% - 8px)", 
margin: "4px"
}} /> 



<img src="./wrk/m7.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
 maxWidth: "340px", 
 minWidth: "200px", 
 borderRadius: "8px", 
 width: "calc(15% - 8px)", 
 margin: "4px"
}} /> 


<img src="./wrk/m8.jpg" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
maxWidth: "340px", 
minWidth: "200px", 
borderRadius: "8px", 
width: "calc(15% - 8px)", 
margin: "4px"
}} /> 


<img src="./wrk/m9.jpg" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
maxWidth: "340px", 
minWidth: "200px", 
borderRadius: "8px", 
width: "calc(15% - 8px)", 
margin: "4px"
}} /> 



<img src="./wrk/m14.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "340px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
  }} /> 
  
  
  <img src="./wrk/m11.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "340px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
  }} /> 


  
<img src="./wrk/m12.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "340px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
  }} /> 
  
  <FlexBreak/>


<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "487px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(33% - 8px)", 
  marginTop:"24px"
  }}>

<h4 className="Outfit-Medium" 
style={{ color: 'rgb(40, 40, 40)', 
marginTop: "20px",
marginBottom: "0px",
margin: "4px",
marginLeft: "24px",
marginRight: "24px",}}>1to1 prototype/ergonomics</h4>


  <p className="Outfit-Light" style={{ margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginTop: "12px",}}>  
  For the design of a piece of furniture with a participatory approach to assembly, 
  there are some ergonomic values to consider besides the seat height, e.g., 
  the gripping spaces and the edges of the individual modules during assembly, 
  even if these are no longer considered after the furniture is assembled. 
  The table shows some reference values from the "Kleine ergonomische Datensammlung", 
  which I commonly use as a reference. Important for my product were primarily the 
  dimensions of the seat surface and the seat height. I calculated with the 95th 
  percentile values for the dimensions due to my own height.
   </p></div>


   <div style={{
    flex: "1 1 auto",
    maxWidth: "487px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(33% - 8px)",
    margin: "4px",
    marginTop:"24px",
    display: "flex",
    flexDirection: "column",
     
  }}>
    <div style={{
      width: "100%",
      paddingBottom: "100%",
      position: "relative",
    }}>
      <img 
        src="./wrk/tabellenergo (2).png" 
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "8px",
      
        }}
      />
    </div>
  </div>


  <FlexBreak />

  <div style={{
    flex: "1 1 auto",
    maxWidth: " 750px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(33% - 8px)",
    margin: "4px",
    marginTop:"24px",
    display: "flex",
    flexDirection: "column",
     
  }}>
    <div style={{
      width: "100%",
      paddingBottom: "100%",
      position: "relative",
    }}>
      <img 
        src="./wrk/1zu11.jpg" 
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "8px",
      
        }}
      />
    </div>
  </div>

  
  <div style={{
    flex: "1 1 auto",
    maxWidth: " 750px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(33% - 8px)",
    margin: "4px",
    marginTop:"24px",
    display: "flex",
    flexDirection: "column",
     
  }}>
    <div style={{
      width: "100%",
      paddingBottom: "100%",
      position: "relative",
    }}>
      <img 
        src="./wrk/1zu12.jpg" 
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "8px",
      
        }}
      />
    </div>
  </div>

  
  
  <div style={{
    flex: "1 1 auto",
    maxWidth: " 750px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(33% - 8px)",
    margin: "4px",
    marginTop:"24px",
    display: "flex",
    flexDirection: "column",
     
  }}>
    <div style={{
      width: "100%",
      paddingBottom: "100%",
      position: "relative",
    }}>
      <img 
        src="./wrk/1zu13.jpg" 
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "8px",
      
        }}
      />
    </div>
  </div>

  <FlexBreak />


  
  
  <img src="./wrk/verbi1comp.png" alt="Fungi Studies" style={{ 
    flex: "1 1 auto", 
    maxWidth: "900px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(75% - 8px)", 
    margin: "4px",
    marginTop:"24px"
    }} /> 


    
<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "487px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(33% - 8px)", 
  marginTop:"24px"
  }}>

  <h4 className="Outfit-Medium" 
  style={{ color: 'rgb(40, 40, 40)', 
  marginTop: "20px",
  marginBottom: "0px",
  margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",}}>The Frame</h4>


  <p className="Outfit-Light" style={{ margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginTop: "12px",}}>  
  The following sketches show the two concepts I considered most 
  appropriate for keeping the frame together. Both are rooted 
  in the "kumiko" concept, so they work just by sticking them together. 
  To me, the connection provided by the flexibility of the wood seemed more 
  interesting, and at the same time, I wouldn't have to use extra materials 
  except for some small wooden plates to stabilize the connection.
   </p></div>


  
  <FlexBreak />


  <img src="./wrk/Verbi2.3comp.png" alt="Fungi Studies" style={{ 
    flex: "1 1 auto", 
    maxWidth: "900px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "calc(25% - 8px)", 
    margin: "4px",
    marginTop:"24px"
    }} /> 



    <img src="./wrk/verbi2comp.png" alt="Fungi Studies" style={{ 
      flex: "1 1 auto", 
      maxWidth: "900px", 
      minWidth: "200px", 
      borderRadius: "8px", 
      width: "calc(75% - 8px)", 
      margin: "4px",
      marginTop:"24px"
      }} /> 
  
    
      <FlexBreak />
      <div style={{  
        display: "flex",
        flex: "1 1 auto",  
        maxWidth: "487px", 
        minWidth: "300px", 
        flexDirection: "column", 
        alignItems: "flex-start",   
        width: "calc(33% - 8px)", 
        marginTop:"24px"
        }}>
      
        <h4 className="Outfit-Medium" 
        style={{ color: 'rgb(40, 40, 40)', 
        marginTop: "20px",
        marginBottom: "0px",
        margin: "4px",
        marginLeft: "24px",
        marginRight: "24px",}}>Choosing the wood</h4>
      
      
        <p className="Outfit-Light" style={{ margin: "4px",
        marginLeft: "24px",
        marginRight: "24px",
        marginTop: "12px",}} >  
        To choose the best of the joints I designed, 
        I made my prototypes from different woods. I tested 
        the woods that are listed in the following  picture on 
        the properties documented in the chart with 
        the wood types.
         </p></div>


         <img src="./wrk/tabelle Holzeigenschaften (2).png" alt="Fungi Studies" style={{ 
          flex: "1 1 auto", 
         maxWidth: "340px", 
         minWidth: "200px", 
         borderRadius: "8px", 
         width: "calc(15% - 8px)", 
         margin: "4px",
         marginTop:"24px"
        }} />  
        
         <FlexBreak />
        
  <div style={{
    flex: "1 1 auto",
    maxWidth: "1500px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(100% - 8px)",
    margin: "4px",
    marginTop:"24px",
    display: "flex",
    flexDirection: "column",
     
  }}>
    <div style={{
      width: "100%",
      paddingBottom: "100%",
      position: "relative",
    }}>
      <img 
        src="./wrk/Wooddescription.jpg" 
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "8px",
      
        }}
      />
    </div>
  </div>

  <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "487px", 
    minWidth: "300px", 
    flexDirection: "column", 
    alignItems: "flex-start",   
    width: "calc(33% - 8px)", 
    marginTop:"24px",

  
    }}>
  
    <h4 className="Outfit-Medium" 
    style={{ color: 'rgb(40, 40, 40)', 
    marginTop: "20px",
    marginBottom: "0px",
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",}}>The connector shape</h4>
  
  
    <p className="Outfit-Light" style={{ margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: "12px",}}>
    
    The project was executed during the Corona lockdowns. For this reason, 
    I created the prototypes at home and 3D-printed the square-drilled 
    and mortised joints for myself. Even though this makes the results 
    less reliable, it was my only way to build a halfway-suitable prototype 
    at the time. After testing, walnut and cherry woods turned out to be the 
    most suitable. I chose to use walnut wood because it is available from 
    many online retailers. For the connection, I chose the third one 
    documented below (marked in red) for the 3D model and later switched 
    to the last one of the prototypes (marked in yellow) for the model I built.
     </p></div>
    
     <FlexBreak />
    
<div style={{
flex: "1 1 auto",
maxWidth: "800px", 
minWidth: "200px", 
borderRadius: "8px", 
width: "calc(50% - 8px)",
margin: "4px",
marginTop:"24px",
display: "flex",
flexDirection: "column",
 
}}>
<div style={{
  width: "100%",
  paddingBottom: "100%",
  position: "relative",
}}>
  <img 
    src="./wrk/Woodproto1.png" 
    style={{
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      borderRadius: "8px",
  
    }}
  />
</div>
</div>

    
<div style={{
  flex: "1 1 auto",
  maxWidth: "800px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)",
  margin: "4px",
  marginTop:"24px",
  display: "flex",
  flexDirection: "column",
   
  }}>
  <div style={{
    width: "100%",
    paddingBottom: "100%",
    position: "relative",
  }}>
    <img 
      src="./wrk/Woodproto2.png" 
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "8px",
    
      }}
    />
  </div>
  </div>

    
  <div style={{
    flex: "1 1 auto",
    maxWidth: "800px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(50% - 8px)",
    margin: "4px",
    marginTop:"24px",
    display: "flex",
    flexDirection: "column",
     
    }}>
    <div style={{
      width: "100%",
      paddingBottom: "100%",
      position: "relative",
    }}>
      <img 
        src="./wrk/Woodproto3.png" 
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "8px",
      
        }}
      />
    </div>
    </div>


    
    <div style={{
      flex: "1 1 auto",
      maxWidth: "800px", 
      minWidth: "200px", 
      borderRadius: "8px", 
      width: "calc(50% - 8px)",
      margin: "4px",
      marginTop:"24px",
      display: "flex",
      flexDirection: "column",
       
      }}>
      <div style={{
        width: "100%",
        paddingBottom: "100%",
        position: "relative",
      }}>
        <img 
          src="./wrk/Woodproto4.png" 
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            borderRadius: "8px",
        
          }}
        />
      </div>
      </div>
<FlexBreak />


<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "487px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(33% - 8px)", 
  marginTop: "24px",
 }}>

 <h4 className="Outfit-Medium" 
 style={{ color: 'rgb(40, 40, 40)', 
 marginTop: "20px",
 marginBottom: "0px",
 margin: "4px",
 marginLeft: "24px",
 marginRight: "24px"}}>The seating panel</h4>




  <p className="Outfit-Light" 
  style={{ margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginTop: "12px",}}> 
  The sketches show the complete assembly of the plate 
  and the plug-in connection for the plate and the frame, 
  as I imagined them. As for the frame, most of 
  the materials used for the seating panel are planned to be 
  from wood as well.
   </p>
   
  
<img src="./wrk/plattenconnectorsketch.png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "340px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  margin: "4px",
  marginTop: "24px",
  }} /> 
   
   </div>


   

    
   <div style={{
    flex: "1 1 auto",
    maxWidth: "800px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(50% - 8px)",
    margin: "4px",
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
     
    }}>
    <div style={{
      width: "100%",
      paddingBottom: "100%",
      position: "relative",
    }}>
      <img 
        src="./wrk/WRK PNGsPortfolio.png" 
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: "8px",
      
        }}
      />
    </div>
    </div>

<FlexBreak />

    <div style={{  
      display: "flex",
      flex: "1 1 auto",  
      maxWidth: "750px", 
      minWidth: "300px", 
      flexDirection: "column", 
      alignItems: "flex-start",   
      width: "calc(50% - 8px)", 
      marginTop: "24px",
    
      }}>
    
      <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>Connector prototypes</h4>
    
    
    
    
      <p className="Outfit-Light" 
      style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}>  
      The prototypes for the connectors were 3D printed, 
      for the same reason I printed the prototypes for 
      the square milled holes of the frame. These 
      connections are also to be considered more 
      conventional, which made 3D printing seem like a good choice.
       </p>
       </div>
 
       <img src="./wrk/3ddruckcomp.jpg" alt="Fungi Studies" style={{ 
        flex: "1 1 auto", 
        maxWidth: "750px", 
        minWidth: "200px", 
        borderRadius: "8px", 
        width: "calc(50% - 8px)", 
        margin: "4px",
        marginTop: "24px",
        }} /> 

       <FlexBreak />

       <img src="./wrk/verbi1.jpg" alt="Fungi Studies" style={{ 
        flex: "1 1 auto", 
        maxWidth: "750px", 
        minWidth: "200px", 
        borderRadius: "8px", 
        width: "calc(50% - 8px)", 
        margin: "4px",
        }} /> 

        <img src="./wrk/verbi2.jpg" alt="Fungi Studies" style={{ 
          flex: "1 1 auto", 
          maxWidth: "750px", 
          minWidth: "200px", 
          borderRadius: "8px", 
          width: "calc(50% - 8px)", 
          margin: "4px"
          }} /> 

          <img src="./wrk/3dprintconnector2.1.jpg" alt="Fungi Studies" style={{ 
            flex: "1 1 auto", 
            maxWidth: "750px", 
            minWidth: "200px", 
            borderRadius: "8px", 
            width: "calc(50% - 8px)", 
            margin: "4px"
            }} /> 
    
            <img src="./wrk/3dprintconnector2.2.jpg" alt="Fungi Studies" style={{ 
              flex: "1 1 auto", 
              maxWidth: "750px", 
              minWidth: "200px", 
              borderRadius: "8px", 
              width: "calc(50% - 8px)", 
              margin: "4px"
              }} /> 


          <div style={{  
            display: "flex",
            flex: "1 1 auto",  
            maxWidth: "487px", 
            minWidth: "300px", 
            flexDirection: "column", 
            alignItems: "flex-start",   
            width: "calc(33% - 8px)", 
            marginTop: "24px",
            }}>
          
            <h4 className="Outfit-Medium" 
            style={{ color: 'rgb(40, 40, 40)', 
            marginTop: "20px",
            marginBottom: "0px",
            margin: "4px",
            marginLeft: "24px",
            marginRight: "24px",}}>The final design</h4>
          
          
          
          
            <p className="Outfit-Light" 
            style={{ margin: "4px",
             marginLeft: "24px",
            marginRight: "24px",
            marginTop: "12px",}}>  
            The sketches show the final shape of the 
            most basic piece of furniture that I wanted 
            to build with the modular system.
             </p>
             </div>
       
             <FlexBreak />


             
        <img src="./wrk/RefinedSketch.png" alt="Fungi Studies" style={{ 
          flex: "1 1 auto", 
          maxWidth: "1500px", 
          minWidth: "200px", 
          borderRadius: "8px", 
          width: "calc(50% - 8px)", 
          margin: "4px",
          }} /> 

          <FlexBreak />

          <div style={{  
            display: "flex",
            flex: "1 1 auto",  
            maxWidth: "487px", 
            minWidth: "300px", 
            flexDirection: "column", 
            alignItems: "flex-start",   
            width: "calc(33% - 8px)", 
            marginTop: "24px",
            }}>
          
            <h4 className="Outfit-Medium" 
            style={{ color: 'rgb(40, 40, 40)', 
            marginTop: "20px",
            marginBottom: "0px",
            margin: "4px",
            marginLeft: "24px",
            marginRight: "24px",}}>3D model</h4>
          
          
          
          
            <p className="Outfit-Light" style={{ margin: "4px",
            marginLeft: "24px",
            marginRight: "24px",
            marginTop: "12px",}}>  
            I used Fusion 360 for modeling the parts and Keyshot for 
            rendering some of the pictures. The pictures below show 
            all the necessary parts for the piece of furniture,  
            including a USB cable for the light underneath the seating panel and 
            some tools for the easy assembly of the design. The animation of the 
            product also shows the packaging I designed for all these pieces.

             </p>
             </div>


    
             <div style={{
              flex: "1 1 auto",
              maxWidth: "1000px", 
              minWidth: "200px", 
              borderRadius: "8px", 
              width: "calc(66% - 8px)",
              margin: "4px",
              marginTop: "24px",
              display: "flex",
              flexDirection: "column",
               
              }}>
              <div style={{
                width: "100%",
                paddingBottom: "100%",
                position: "relative",
              }}>
                <img 
                  src="./wrk/rendertop.jpg" 
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: "8px",
                
                  }}
                />
              </div>
              </div>



              
             <div style={{
              flex: "1 1 auto",
              maxWidth: "750px", 
              minWidth: "200px", 
              borderRadius: "8px", 
              width: "calc(50% - 8px)",
              margin: "4px",
              display: "flex",
              flexDirection: "column",
               
              }}>
              <div style={{
                width: "100%",
                paddingBottom: "100%",
                position: "relative",
              }}>
                <img 
                  src="./wrk/renderschraub.jpg" 
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: "8px",
                
                  }} />

              </div>
              </div>

              <div style={{
                flex: "1 1 auto",
                maxWidth: "750px", 
                minWidth: "200px", 
                borderRadius: "8px", 
                width: "calc(50% - 8px)",
                margin: "4px",
                display: "flex",
                flexDirection: "column",
                 
                }}>
                <div style={{
                  width: "100%",
                  paddingBottom: "100%",
                  position: "relative",
                }}>
                  <img 
                    src="./wrk/renderhammer.jpg" 
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: "8px",
                  
                    }} />
  
                </div>
                </div>



                <FlexBreak />



                <video
     src="./wrk/Kumiko System Animation.mp4"
     alt="Aufbau Animation komplett"
     style={{
       flex: "1 1 auto",
       maxWidth: "1500px",
       minWidth: "300px",
       borderRadius: "8px",
       width: "calc(100% - 8px)",
       margin: "4px",
       marginTop: "24px",
     }}
     controls
   />  



   
   <FlexBreak />


   <img src="./wrk/kumiko system tz .png" alt="Fungi Studies" style={{ 
    flex: "1 1 auto", 
    maxWidth: "1500px", 
    minWidth: "300px", 
    borderRadius: "8px", 
    width: "calc(100% - 8px)", 
    margin: "4px"
  }} /> 
  
  <FlexBreak />
          <div style={{  
            display: "flex",
            flex: "1 1 auto",  
            maxWidth: "487px", 
            minWidth: "300px", 
            flexDirection: "column", 
            alignItems: "flex-start",   
            width: "calc(33% - 8px)", 
          
            }}>
          
            <h4 className="Outfit-Medium" 
            style={{ color: 'rgb(40, 40, 40)', 
            marginTop: "20px",
            marginBottom: "0px",
            margin: "4px",
            marginLeft: "24px",
            marginRight: "24px",}}>Assembly of the frame</h4>
          
          
          
          
            <p className="Outfit-Light" style={{ margin: "4px",
            marginLeft: "24px",
            marginRight: "24px",
            marginTop: "12px",}}>  
            The first pictures show the assembly of the frame 
            with the tools provided in the "set" I designed. 
            The second row shows the assembly of the frame and the seating panel.
             </p>
             </div>

             <img src="./wrk/Assembly1.png" alt="Fungi Studies" style={{ 
              flex: "1 1 auto", 
              maxWidth: "486px", 
              minWidth: "200px", 
              borderRadius: "8px", 
              width: "calc(33% - 8px)", 
              margin: "4px"
              }} /> 
              
              
              <img src="./wrk/Assembly2.png" alt="Fungi Studies" style={{ 
              flex: "1 1 auto", 
              maxWidth: "486px", 
              minWidth: "200px", 
              borderRadius: "8px", 
              width: "calc(33% - 8px)", 
              margin: "4px"
              }} /> 
            
            
            
              
            <img src="./wrk/assembly1.0.png" alt="Fungi Studies" style={{ 
              flex: "1 1 auto", 
              maxWidth: "486px", 
              minWidth: "200px", 
              borderRadius: "8px", 
              width: "calc(33% - 8px)", 
              margin: "4px"
              }} /> 

 
              <img src="./wrk/Assembly1.1.png" alt="Fungi Studies" style={{ 
                flex: "1 1 auto", 
                maxWidth: "650px", 
                minWidth: "200px", 
                borderRadius: "8px", 
                width: "calc(33% - 8px)", 
                margin: "4px"
                }} /> 


   </div>;

const Content5 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px",
}}>


<img src="./Fungi/Sample1.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample2.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample3.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample4.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample5.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample6.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample8.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample9.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample10.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 
<img src="./Fungi/Sample11.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample12.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample13.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample14.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample15.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample16.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample17.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample18.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 



<img src="./Fungi/Sample19.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 


<img src="./Fungi/Sample20.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 


<img src="./Fungi/Sample21.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 


<img src="./Fungi/Sample22.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 


<img src="./Fungi/Sample23.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 


<img src="./Fungi/Sample24.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample25.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample26.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample27.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 

<img src="./Fungi/Sample28.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 


<img src="./Fungi/Sample32.png" alt="Fungi Studies" style={{ 
flex: "1 1 auto", 
  maxWidth: " 320px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} /> 





<div style={{  
display: "flex",
flex: "1 1 auto",  
maxWidth: "484px", 
minWidth: "200px", 
flexDirection: "column", 
alignItems: "flex-start",  
border: "1px solid black", 
borderRadius: "8px", 
margin: "4px 4px 16px 6px",
padding: "4px",
paddingLeft: "18px",
paddingRight: "18px",
}}>
<h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px" ,marginBottom: "0px"}}>Intent</h4>


 <p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "0px" ,marginLeft: "0px"}} >
 I started this project because I am fascinated by the idea of working with living, growing organisms to 
 produce sustainable materials. As one of these materials, mycelium 
 has gained popularity in recent years due to its awesome material 
abilities, such as temperature- and sound-insulation properties, 
its toughness compared to its low weight, and the fact that 
it is completely biodegradable.
Because a good friend and fellow student of mine, Clemens Domsch, 
was interested in this topic too, we initiated it as a group project.
 </p></div>

 <FlexBreak />

 <img src="./Fungi/b (3 von 3).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "800px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px",
  marginBottom: "24px"
}} /> 




<img src="./Fungi/b (1 von 3).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "800px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px",
  marginBottom: "24px"
}} /> 



<img src="./Fungi/b (2 von 3).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "800px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px",
  marginBottom: "24px"
}} /> 
  
<FlexBreak />




<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "484px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  border: "1px solid black", 
borderRadius: "8px", 
margin: "4px 4px 24px 4px",
padding: "4px",
paddingLeft: "18px",
paddingRight: "18px",
}}>
<h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px" ,marginBottom: "0px"}}>What is mycelium?</h4>


 <p className="Outfit-Light" >
 In general, the word fungus is used to describe the visible fruiting body of fungi. 
 Mycelium is the sum of all hyphae (the thread-like cells) of a fungus. 
 In addition to plants and animals, fungi form the third
 major kingdom of eukaryotic organisms (organisms with a cell nucleus).  
 Unlike plants, they cannot perform synthesis. They therefore take up 
their food in dissolved form from their environment. The diversity of 
species ranges from complex, multicellular organisms to single-celled 
organisms like baker's yeast. In nature, fungi are essential for the 
maintenance of ecosystems. They form symbioses with plants and provide 
them with important nutrients, such as phosphate, which they need for 
their growth.

 </p>
 
 </div>

 <div style={{
  flex: "1 1 auto",
  minWidth: "340px",
  maxWidth: "1000px",
  width: "calc(45% - 8px)",
  borderRadius: "8px",
  margin: "4px",
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column",   
}}>
  <div style={{
    width: "100%",
    paddingBottom: "100%",
    position: "relative",
  }}>
    <video 
      src="./Fungi/TimelapseC-min.mp4" 
      alt="Fungi Studies"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "8px",  
      }}
      autoPlay
      loop
      muted
    />
  </div>
</div>
<FlexBreak />


<div style={{
  flex: "1 1 auto",
  minWidth: "340px",
  maxWidth: "1000px",
  width: "calc(45% - 8px)",
  borderRadius: "8px",
  margin: "4px 4px 24px 4px",
  display: "flex",
  flexDirection: "column"
   
}}>
  <div style={{
    width: "100%",
    paddingBottom: "100%",
    position: "relative",
  }}>
    <img 
      src="./Fungi/compo.jpg" 
      alt="Fungi Studies"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "8px",
        rowgap: "100px",
    
      }}
    />
  </div>
</div>

<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "484px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  border: "1px solid black", 
borderRadius: "8px", 
margin: "4px 4px 24px 4px",
padding: "4px",
paddingLeft: "18px",
paddingRight: "18px",
}}>
<h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px" ,marginBottom: "0px"}}>Evaluation</h4>


<p className="Outfit-Light" >
We evaluated the samples based on some basic 
properties we categorized as relevant when 
working with the materials. The most relevant to us were: <br /> 
<br />
• the kind of fungus <br />
• material it grew on <br />
• material shrinkadge <br />
• density with which the mycelium grew <br />
• strength of the dried sample<br />
• the Time the sample grew<br />
  <br />
  However, our focus was heavily on the materials that we could use and
   the understanding of the workflow we needed to establish to grow 
   mycelium-based materials. For this reason, we did document the amount 
   of material and water we used for each sample, but didn't document 
   the weight of the dry samples.The exact amounts of water, materials, 
   and the way we worked are documented in two booklets. 
</p></div>

<FlexBreak />



<div style={{ 
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "488px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  border: "1px solid black", 
borderRadius: "8px", 
margin: "4px 4px 24px 4px",
padding: "4px",
paddingLeft: "18px",
paddingRight: "18px",
}}>
<h3 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px" ,marginBottom: "0px"}}>Process</h3>
 <p className="Outfit-Light">
 After our research, we started to grow the necessary mycelium according 
 to the sources we found. We produced our own liquid mycelium from 
 spores of fungi we purchased online in the form of mushroom dowels, 
 collected right from nature, or even from mushrooms we bought 
 at the grocery store. We bred: <br />
<br />
• Oyster mushroom (Pleurotus ostreatus)<br />
• Shii-Take (Lentinula edodes)<br />
• Ganoderma lucidum<br />
• Tinder fungus (Fomes fomentarius)<br />
• king trumpet mushroom (Pleurotus eryngii)<br />
• Pestalotiopsis microspora ( Decomposes Polyuretan)<br />


</p></div>






<div style={{ 
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "488px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  border: "1px solid black", 
borderRadius: "8px", 
margin: "4px 4px 24px 4px",
padding: "4px",
paddingLeft: "18px",
paddingRight: "18px",
}}>

 <p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px" ,marginBottom: "0px"}} >
 After breeding the liquid mycelium, we filled a 
 syringe to inject the mycelium into  our prepared 
 materials. Not all the samples we grew were successful; 
 in fact, some of the samples with fungi like 
 Lenticular erodes led to no successful results at all.<br />
 <br />
 The best results came from the samples, which were bred from 
 Pleurotus Ostreatus, Fomes Fomentarius, and Ganoderma Lucidum. 
 Especially the samples with wood-decaying fungus were 
 astonishingly hard after drying.<br />
 <br />
 We documented the complete process behind growing the 
 fungi and the samples we made in a protocol booklet, 
 just like I documented the process of growing the 
 fungi and preparations for every step. 
</p></div>




<div style={{
  flex: "1 1 auto",
  minWidth: "200px",
  maxWidth: "484px",
  width: "calc(50% - 8px)",
  borderRadius: "8px",
  margin: "4px",
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column",
   
}}>
  <div style={{
    width: "100%",
    paddingBottom: "100%",
  
    position: "relative",
  }}>
    <img 
      src="./Fungi/squareshroom.jpg" 
      alt="Fungi Studies"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "8px",
    
      }}
    />
  </div>
</div>

<FlexBreak />

<div style={{
  flex: "1 1 auto",
  minWidth: "200px",
  maxWidth: "1250px",
  width: "calc(50% - 8px)",
  borderRadius: "8px",
  margin: "4px",
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column",
   
}}>
  <div style={{
    width: "100%",
    paddingBottom: "100%",
  
    position: "relative",
  }}>
    <img 
      src="./Fungi/20220522_115343 (2).jpg" 
      alt="Fungi Studies"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "8px",
    
      }}
    />
  </div>
</div>





<div style={{ 
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "484px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  border: "1px solid black", 
borderRadius: "8px", 
margin: "4px 4px 24px 4px",
padding: "4px",
paddingLeft: "18px",
paddingRight: "18px",
}}>

 <p className="Outfit-Light" >
We tried growing mycelium on many different materials.  
Some of these were: <br /> 
<br /> 

• Carrot-and-Apple peels<br /> 
• Coffee grounds <br />            
• Tea<br /> 
• Peanut hulls<br /> 
• Avocado peels<br /> 
• Beer spent grains <br /> 
• Lime peels <br /> 
• Banana peels<br /> 
• Wood chips(different types)  <br /> 
<br /> 
And other. While doing our research, we have already found some
information regarding a few of the materials and their potential,
for example, about the wood chips. For other materials, we 
tested the percentage of water and dry mass that was necessary
until we found the right balance.
</p></div>
<FlexBreak />



<div style={{
  flex: "1 0 auto",
  minWidth: "200px",
  maxWidth: "1500px",
  width: "calc(60% - 8px)",
  borderRadius: "8px",
  margin: "4px",
  marginBottom: "24px",
  
  display: "flex",
  flexDirection: "column",
   
}}>
<div style={{
  width: "100%",
  paddingBottom: "100%",

  position: "relative",
}}>
  <img 
    src="./Fungi/grown.jpg" 
    alt="Fungi Studies"
    style={{
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      objectFit: "contain",
      borderRadius: "8px",
  
    }}
  />
</div>
</div>

<FlexBreak />

<div style={{
  flex: "1 1 auto",
  minWidth: "340px",
  maxWidth: "488px",
  width: "calc(33% - 8px)",
  borderRadius: "8px",
  margin: "4px",
  display: "flex",
  flexDirection: "column",
   
}}>
  <div style={{
    width: "100%",
    paddingBottom: "100%",
  
    position: "relative",
  }}>
    <img 
      src="./Fungi/team (2).JPG" 
      alt="Fungi Studies"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "8px",
    
      }}
    />
  </div>
</div>



<div style={{ 
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "488px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start", 
  border: "1px solid black", 
  borderRadius: "8px", 
  margin: "4px",
  padding: "4px",
  paddingLeft: "24px",
  paddingRight: "24px",
  
 
  }}>

 <h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px", marginBottom: "0px"}}>Thanks to my good friend Clemens Domsch</h4>
 <p className="Outfit-Light" >
 For this project, I worked together with my student and good friend Clemens Domsch, 
 to whom I would like to express a special thanks at this point. 
 It was nice and interesting to do a project together and to coordinate 
 and divide the work, especially because it was the first real group 
 project after the Corona crisis. 
</p></div>


</div>;

const Content6= <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>
<img src="./öffner/Öffner Perspective.png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "1500px", 
  minWidth: "300px", 
  borderRadius: "8px", 
  width: "calc(100% - 8px)", 
  margin: "4px"
}} />

<img src="./öffner/holz verbi oben (1 von 2).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "750px", 
  minWidth: "125px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)", 
  margin: "4px"
}} />

<img src="./öffner/holz verbi oben (2 von 2).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "750px", 
  minWidth: "125px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)", 
  margin: "4px"
}} />

<img src="./öffner/Öffner unten.png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "1500px", 
  minWidth: "300px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)", 
  margin: "4px"
}} />

<FlexBreak />


   <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "430px", 
    minWidth: "300px", 
    flexDirection: "column", 
    alignItems: "flex-start",  
    width: "calc(33% - 8px)", 
      marginTop:"24px"
      }}>
  
    <h4 className="Outfit-Medium" 
    style={{ color: 'rgb(40, 40, 40)', 
    marginTop: "20px",
    marginBottom: "0px",
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",}}>Opener for people with limited haptic abilities</h4>
   
  
  <p className="Outfit-Light" style={{ margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginTop: "12px",}}>
  I designed this product in the third semester. 
  The task we got for the course was to create an opener 
  that can open a wine bottle, a can, or a corked bottle. 
  This can opener is designed for people with limited haptic 
  abilities. At first, the opener is placed on top of the can 
  with the blades facing downward. By spinning the wheel on 
  top of the opener, the distance between the blades can be 
  set as necessary. 
   Then the blades are pushed into the can. By pushing and 
  dragging the grip backwards, a ratchet wrench-mechanism is 
  used to push the two blades in a circular movement through the can lid.
   
      </p></div>

      <img src="./öffner/explodedopener with descriptions3.png" alt="Fungi Studies" style={{ 
        flex: "1 1 auto", 
        maxWidth: "1250px", 
        minWidth: "300px", 
        borderRadius: "8px", 
        width: "calc(50% - 8px)", 
        margin: "4px",
        marginBottom:"24px"
      }} />

      <div style={{  
        display: "flex",
        flex: "1 1 auto",  
        maxWidth: "430px", 
        minWidth: "300px", 
        flexDirection: "column", 
        alignItems: "flex-start",  
        width: "calc(50% - 8px)", 
       marginTop:"24px"
      }}>
  
      <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>Iterations</h4>
       
      
      <p className="Outfit-Light" style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}> 
      After sketching, I usually use prototypes to find problems and 
      solutions within my designs. I created a modular prototype 
      of the opener to check which grip angle and/or grip angles 
      in general are most useful for my design. After testing all 
      of them, the straight one seemed to be the best choice.
          </p></div>
       
          <FlexBreak />

          <img src="./öffner/openersketch.png" alt="Fungi Studies" style={{ 
            flex: "1 1 auto", 
            maxWidth: "1500px", 
            minWidth: "300px", 
            borderRadius: "8px", 
            width: "calc(100% - 8px)", 
            margin: "4px"
          }} />
      
          <FlexBreak />


          
          <img src="./öffner/öffner prototypes.png" alt="Fungi Studies" style={{ 
            flex: "1 1 auto", 
            maxWidth: "1500px", 
            minWidth: "300px", 
            borderRadius: "8px", 
            width: "calc(100% - 8px)", 
            margin: "4px"
          }} />


          <img src="./öffner/holz verbi oben (18 von 25).png" alt="Fungi Studies" style={{ 
            flex: "1 1 auto", 
            maxWidth: "1500px", 
            minWidth: "300px", 
            borderRadius: "8px", 
            width: "calc(50% - 8px)", 
            margin: "4px"
          }} />

          <img src="./öffner/holz verbi oben (10 von 25).png" alt="Fungi Studies" style={{ 
            flex: "1 1 auto", 
            maxWidth: "1500px", 
            minWidth: "300px", 
            borderRadius: "8px", 
            width: "calc(50% - 8px)", 
            margin: "4px"
          }} />
    

</div>;







const Content7 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  
}}>




<img src="./images/0 (41 von 61).jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "2000px", 
  minWidth: "280px", 
  borderRadius: "8px", 
  width: "calc(70% - 8px)", 
  margin: "4px 4px 4px 4px",
}} /> 


 <div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "1000px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  
  width: "calc(16% - 8px)", 

  }}>



  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    marginRight: "4px",  
    width: "calc(100% - 8px)",  
  }}>
    <img src="./images/für details (41 von 61) (7).jpg" alt="Fungi Studies" style={{ 
      flex: "1 1 auto", 
      maxWidth: "1250px", 
      minWidth: "200px", 
      borderRadius: "8px", 
      width: "100%",  
     margin: "4px 4px 0px 4px"  
    }} />

  <h4 className="Outfit-Medium" 
  style={{ color: '#FFFFFF', 
  margin: "4px",
  marginTop: "24px",
  marginLeft: "24px",
  marginRight: "24px",
  marginBottom: "16px",}}>Sketches</h4>

  <p className="Outfit-Light" style={{  color: '#FFFFFF', margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginBottom: "24px",}}>
  One of my private projects is an ongoing collection of 
  designs for unconventional instruments, vehicles, and other objects. 
  At the moment, it consists of around 26 sketches with 
  a size between A3 and A0.
     </p>
     </div>
     </div>



     

<div style={{  
  display: "flex",
  flexDirection: "column", 
  margin: "0 4px 0 4px",
}}>

<div style={{  
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
  margin: "4px 4px 0 0", 
}}>
  <img src="./images/Airship big.jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "30px", 
    borderRadius: "8px", 
    width: "100%", 
  }} />
  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    margin: "0 4px 0 4px",  
    width: "calc(29.5% - 8px)",  
  }}>
    <img src="./images/Airship Ballon downside.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "30px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "0 0 4px 4px"  
    }} />

    <img src="./images/Airship front close.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "30px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0 4px 4px",  
     
    }} />

    <img src="./images/Airship hook.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "30px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0 4px 4px"  
    }} />

    <img src="./images/Airship bird Poop.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "30px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0 4px 4px",  
     
    }} />

    
    <img src="./images/Airship rohre2 (3).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "30px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0 0px 4px",  
     
    }} />
  
  </div>
</div>
</div> 


<FlexBreak />


<div style={{  
  display: "flex",
  flexDirection: "column", 
  margin: "4px 4px 0 4px",
}}>

<div style={{  
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
  margin: "0 4px 4px 0", 
}}>
  <img src="./images/complete2.jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%", 
  }} />
</div>

  <div style={{  
    display: "flex",
    flexDirection: "row", 
    alignItems: "flex-start",
    marginBottom: "4px",  
    width: "100%",  
  }}>
    <img src="./images/Cabin_left3.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "30px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 0"  
    }} />

    <img src="./images/cabin middle.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "30px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 4px",  
    }} />

    <img src="./images/cabin right.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "30px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 4px",  
    }} />

    <img src="./images/detail2.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "30px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 0 0 4px"
    }} />

  </div>
</div> 



<div style={{  
  display: "flex",
  flexDirection: "row", 
  margin: "4px 0 0 4px",
  
}}>
  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    marginRight: "4px",  
    width: "calc(26.5% - 8px)",  
  }}>
    <img src="./images/0 (a von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0"  
    }} />

    <img src="./images/0 (b von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0",  
     
    }} />
  </div>

  <div style={{  
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "calc(74% - 8px)",
    margin: "4px",  
  }}>
    <img src="./images/0 (33 von 61)cropped.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "2500px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
        
    }} />
  </div>
</div> 




<FlexBreak />


<div style={{  
  display: "flex",
  flexDirection: "column", 
  margin: "4px 4px 0 4px",
}}>

<div style={{  
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
  margin: "0 4px 4px 0", 
}}>
  <img src="./images/0 (13 von 61).jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%", 
  }} />
</div>

  <div style={{  
    display: "flex",
    flexDirection: "row", 
    alignItems: "flex-start",
    marginBottom: "4px",  
    width: "100%",  
  }}>
    <img src="./images/3 (h von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 0"  
    }} />

    <img src="./images/3 (g von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 4px",  
    }} />

    <img src="./images/3 (fvon 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 0 0 4px",  
    }} />
  </div>
</div> 




<div style={{  
  display: "flex",
  flexDirection: "column", 
  margin: "4px 4px 0 4px",
}}>

<div style={{  
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
  margin: "0 4px 4px 0", 
}}>
  <img src="./images/0 (10 von 61)2.jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%", 
  }} />
</div>

  <div style={{  
    display: "flex",
    flexDirection: "row", 
    alignItems: "flex-start",
    marginBottom: "4px",  
    width: "100%",  
  }}>
    <img src="./images/für details (10 von 61) (14).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 0"  
    }} />

    <img src="./images/für details (10 von 61) (6).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 4px",  
    }} />

    <img src="./images/für details (10 von 61) (8).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 0 0 4px",  
    }} />
  </div>
</div> 




<div style={{  
  display: "flex",
  flexDirection: "row", 
  margin: "4px 0 0 4px",
  
}}>
<div style={{  
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "calc(74% - 8px)",
  margin: "0 4px 4px 0",  
}}>
  <img src="./images/0 (22 von 61) (2).jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%"
  }} />
</div>

  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    marginRight: "4px",  
    width: "calc(26.5% - 8px)",  
  }}>
    <img src="./images/für details (21 von 61) (3).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "0 0 4px 4px"  
    }} />

    <img src="./images/für details (21 von 61) (2).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0 0 4px",  
     
    }} />
  </div>
</div> 



<div style={{  
  display: "flex",
  flexDirection: "row", 
  margin: "0 0 0 4px",
  
}}>
  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    marginRight: "4px",  
    width: "calc(26.5% - 8px)",  
  }}>
    <img src="./images/g (7 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0"  
    }} />

    <img src="./images/g (9 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0",  
     
    }} />
  </div>

  <div style={{  
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "calc(74% - 8px)",
    margin: "4px",  
  }}>
    <img src="./images/0 (7 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "2500px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
        
    }} />
  </div>
</div> 




<div style={{  
  display: "flex",
  flexDirection: "row", 
  margin: "4px 0 0 4px",
  
}}>
<div style={{  
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "calc(74% - 8px)",
  margin: "0 4px 0 0",  
}}>
  <img src="./images/0 (37 von 61).jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%"
  }} />
</div>

  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    marginRight: "4px",  
    width: "calc(26.5% - 8px)",  
  }}>
    <img src="./images/r (37 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "0 0 4px 4px"  
    }} />

    <img src="./images/r (39 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0 0 4px",  
     
    }} />
  </div>
</div> 



<div style={{  
  display: "flex",
  flexDirection: "row", 
  margin: "4px 0 0 4px",
  
}}>
  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    marginRight: "4px",  
    width: "calc(26.5% - 8px)",  
  }}>
    <img src="./images/blueboarddetail1 (4).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0"  
    }} />

    <img src="./images/blueboarddetail2 (4).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0",  
     
    }} />
  </div>

  <div style={{  
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "calc(74% - 8px)",
    margin: "4px",  
  }}>
    <img src="./images/0 (31 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "2500px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
        
    }} />
  </div>
</div> 


<div style={{  
  display: "flex",
  flexDirection: "column", 
  margin: "4px 4px 0 4px",
}}>

<div style={{  
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
  margin: "0 4px 4px 0", 
}}>
  <img src="./images/0 (16 von 61).jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%", 
  }} />
</div>

  <div style={{  
    display: "flex",
    flexDirection: "row", 
    alignItems: "flex-start",
    marginBottom: "4px",  
    width: "100%",  
  }}>
    <img src="./images/boearddetail1.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 0"  
    }} />

    <img src="./images/boearddetail2.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 4px",  
    }} />

    <img src="./images/boearddetail3.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 0 0 4px",  
    }} />
  </div>
</div> 

<div style={{  
  display: "flex",
  flexDirection: "row", 
  marginTop: "4px",
  
}}>
<div style={{  
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "calc(74.125% - 8px)",
  margin: "0 4px 4px 4px",  
}}>
  <img src="./images/Boneharp0.jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%"
  }} />
</div>
  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    margin: "0 4px 0 0",  
    width: "calc(25.875% - 8px)",  
  }}>
    <img src="./images/Boneharp1.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "0 4px 4px 4px"  
    }} />

    <img src="./images/Boneharp3.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 4px 4px 4px",  
     
    }} />

    
    <img src="./images/Boneharp2.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 4px 4px 4px",  
     
    }} />

    <img src="./images/Boneharp9.jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 4px 4px 4px",  
     
    }} />

    
  </div>
</div> 

<div style={{  
  display: "flex",
  flexDirection: "row", 
  marginTop: "4px",
  
}}>

  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    margin: "0 4px 0 0",  
    width: "calc(25.875% - 8px)",  
  }}>
    <img src="./images/0 (19 von 61) (8).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "0 4px 4px 4px"  
    }} />

    <img src="./images/0 (19 von 61) (9).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 4px 4px 4px",  
     
    }} />

    
    <img src="./images/0 (19 von 61) (10).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 4px 4px 4px",  
     
    }} />

    <img src="./images/0 (19 von 61) (13).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 4px 4px 4px",  
     
    }} />

    
  </div>
  <div style={{  
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "calc(74.125% - 8px)",
    margin: "0 0 4px 8px",  
  }}>
    <img src="./images/0 (19 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "2500px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%"
    }} />
  </div>
</div> 

<div style={{  
  display: "flex",
  flexDirection: "row", 
  margin: "4px 0 0 4px",
  
}}>
<div style={{  
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "calc(74% - 8px)",
  margin: "0 4px 4px 0",  
}}>
  <img src="./images/0 (6 von 61).jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%"
  }} />
</div>

  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    margin: "0 8px 0 0",  
    width: "calc(26.5% - 8px)",  
  }}>
    <img src="./images/f (6 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "0 0 4px 4px"  
    }} />

    <img src="./images/f (7 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0 0 4px",  
     
    }} />
  </div>
</div> 



<div style={{  
  display: "flex",
  flexDirection: "row", 
  margin: "4px 0 0 4px",
  
}}>
  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    marginRight: "4px",
    width: "calc(26.5% - 8px)",  
  }}>
    <img src="./images/0 (47 von 61) (10).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "0 0 4px 0"  
    }} />

    <img src="./images/0 (47 von 61) (8).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0",  
     
    }} />
  </div>

  <div style={{  
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "calc(74% - 8px)",
    margin: "0 4px 4px 4px",  
  }}>
    <img src="./images/0 (47 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "2500px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
        
    }} />
  </div>
</div> 



<div style={{  
  display: "flex",
  flexDirection: "row", 
  margin: "4px 0 0 4px",
  
}}>
<div style={{  
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "calc(74% - 8px)",
  margin: "0 4px 4px 0",  
}}>
  <img src="./images/0 (52 von 61).jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%"
  }} />
</div>

  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    margin: "0 8px 0 0",  
    width: "calc(26.45% - 8px)",  
  }}>
    <img src="./images/für details (52 von 61) (8).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "0 0 4px 4px"  
    }} />

    <img src="./images/für details (52 von 61) (4).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0 0 4px",  
     
    }} />
  </div>
</div> 



<div style={{  
  display: "flex",
  flexDirection: "column", 
  margin: "4px 4px 0 4px",
}}>

<div style={{  
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
  margin: "0 4px 4px 0", 
}}>
  <img src="./images/0 (28 von 61).jpg" alt="Fungi Studies" style={{ 
    maxWidth: "2500px", 
    minWidth: "50px", 
    borderRadius: "8px", 
    width: "100%", 
  }} />
</div>

  <div style={{  
    display: "flex",
    flexDirection: "row", 
    alignItems: "flex-start",
    marginBottom: "4px",  
    width: "100%",  
  }}>
    <img src="./images/für details (28 von 61) (2).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 0"  
    }} />

    <img src="./images/für details (28 von 61) (4).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 4px 0 4px",  
    }} />

    <img src="./images/für details (28 von 61) (6).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%", 
      margin: "4px 0 0 4px",  
    }} />
  </div>
</div> 



<div style={{  
  display: "flex",
  flexDirection: "row", 
  margin: "4px 0 0 4px",
  
}}>
  <div style={{  
    display: "flex",
    flexDirection: "column", 
    alignItems: "flex-start",
    marginRight: "4px",
    width: "calc(26.5% - 8px)",  
  }}>
    <img src="./images/0 (61 von 61) (9).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "0 0 4px 0"  
    }} />

    <img src="./images/0 (61 von 61) (2).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "800px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
      margin: "4px 0",  
     
    }} />
  </div>

  <div style={{  
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "calc(74% - 8px)",
    margin: "0 4px 0 4px",  
  }}>
    <img src="./images/0 (61 von 61).jpg" alt="Fungi Studies" style={{ 
      maxWidth: "2000px", 
      minWidth: "50px", 
      borderRadius: "8px", 
      width: "100%",  
        
    }} />
  </div>
</div> 




</div>;






const Content8 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "Left", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>



<img src="./images/bw loop 1.gif" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "600px", 
  minWidth: "300px", 
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px"
}} /> 

<img src="./images/BWLoop3.gif" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "600px", 
  minWidth: "300px", 
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px"
}} /> 



<img src="./images/BWLoop2gifmin.gif" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "600px", 
  minWidth: "300px", 
  borderRadius: "8px", 
  width: "calc(33% - 8px)", 
  margin: "4px"
}} /> 



<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "430px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginBottom: "24px",
  width: "calc(28% - 8px)", 

  }}>

<h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>INK</h4>
 

 <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
 INK is a private project I started a while ago, to get my hands on Blender's 
 geometry node system. Other aspects are composition and working with only black 
 and white. Furthermore, I use them to get more familiar with effectors and physics- simulations, 
 just as i used them for the particles. The number of animations will be continuously 
 updated as I create them in my free time, between other, bigger projects.
    </p></div>
    </div>





const Content9 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/9.1-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/9.4-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>



<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/8.7_1-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/7.1-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/7.4-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/7.8-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/20.1_1-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/20.7-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/20.8-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video> 

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/17.1-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video> 

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/17.1_1.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video> 

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/Rad/17.8-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video> 




<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "430px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginBottom: "24px",
  width: "calc(70% - 8px)", 

  }}>

  <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>The Microcosm</h4>
 

 <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
 I had the idea for "The Microcosm" in 2021. I'm fascinated 
 by the drawings of Ernst Haeckel and wanted to create something 
 inspired by his artworks. Originally, I had the intent to create 
 a NFT collection of 50 to 150 animations of shapes as they can 
 be found in the skeletons of radiolarias. In the process, I 
 created 20 3D models and a dozen procedural materials. I later 
 decided not to upload the animations as a NFT, as it was much 
 more of an exercise in the basics of modeling and sculpting in 
 Blender. This window shows 12 of the over 60 animations I made.
  </p></div>
    </div>



const Content10 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>
<video
      src="./Bottle/greybottle30fps2.mp4"
      alt="Aufbau Animation komplett"
      style={{
        flex: "1 1 auto",
        maxWidth: "1600px",
        minWidth: "300px",
        borderRadius: "8px",
        width: "calc(100% - 8px)",
        margin: "4px",
      }}
      controls
    />  
    

    <div style={{  
      display: "flex",
      flex: "1 0 auto",  
      maxWidth: "487px", 
      minWidth: "300px", 
      flexDirection: "column", 
      alignItems: "flex-start",  
      
      width: "calc(34% - 8px)", 
      marginTop:"24px"
      }}>
    
    
      <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>A possible usecase for mycelium</h4>
    
    
      <p className="Outfit-Light" style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}> 
      This product design is related to the project "Fungi Studies." 
      My intention was to design an example-product that shows a potential use case for mycelium. 
      The result is the fungus bottle. It uses the good insulation properties of mycelium 
      in the form of a multipurpose bottle with thermos properties. Additionally, I wanted 
      some parts to fulfill multiple purposes, for example, being usable as plates and cutlery.
       </p></div>



      <img src="/Bottle/bottlekompfreigestellt.png" alt="Fungi Studies" style={{ 
        flex: "1 1 auto", 
        maxWidth: "1500px", 
        minWidth: "200px", 
        borderRadius: "8px", 
        width: "calc(100% - 8px)", 
        margin: "4px",
        marginTop: "24px",
      }} /> 

       
    <div style={{  
      display: "flex",
      flex: "1 0 auto",  
      maxWidth: "487px", 
      minWidth: "300px", 
      flexDirection: "column", 
      alignItems: "flex-start",  
      width: "calc(34% - 8px)", 
      marginTop:"24px"
      }}>
    
    
      <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>Iterations</h4>
    
    
      <p className="Outfit-Light" style={{ margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",
      marginTop: "12px",}}>  
      I started out with a lot of ideas for what 
      to do with the mycelium, from bird houses that 
      can be left in the woods to decay automatically over 
      fireworks casings to modular bobby cars or balance bikes 
      to grow at home. After the consultations, I decided to 
      realize the concept of a mycelium-based thermos flask. 
      I made two shapes of paper prototypes in four different 
      sizes for volumes of 0.75 liters to 1.25 liters. The 
      final 1:1 paper prototype is shown in the pictures 
      below and has a capacity of 0.75 liters. 
       </p></div>

       <FlexBreak />

      
      <img src="/Bottle/20220619_203716.jpg" alt="Fungi Studies" style={{ 
        flex: "1 1 auto", 
        maxWidth: "750px", 
        minWidth: "200px", 
        borderRadius: "8px", 
        width: "calc(50% - 8px)", 
        margin: "4px",
        marginTop: "24px",
      }} /> 

      <img src="/Bottle/20220619_205000_1.jpg" alt="Fungi Studies" style={{ 
        flex: "1 1 auto", 
        maxWidth: "750px", 
        minWidth: "200px", 
        borderRadius: "8px", 
        width: "calc(50% - 8px)", 
        margin: "4px",
        marginTop: "24px",

      }} /> 
      
      <img src="/Bottle/20220619_204155 (2).jpg" alt="Fungi Studies" style={{ 
        flex: "1 1 auto", 
        maxWidth: "1500px", 
        minWidth: "200px", 
        borderRadius: "8px", 
        width: "calc(100% - 8px)", 
        margin: "4px"
      }} /> 

      
      <div style={{  
        display: "flex",
        flex: "1 0 auto",  
        maxWidth: "487px", 
        minWidth: "300px", 
        flexDirection: "column", 
        alignItems: "flex-start",  
        width: "calc(33% - 8px)", 
      marginTop:"24px"
      }}>
      
      
        <h4 className="Outfit-Medium" 
      style={{ color: 'rgb(40, 40, 40)', 
      marginTop: "20px",
      marginBottom: "0px",
      margin: "4px",
      marginLeft: "24px",
      marginRight: "24px",}}>Final prototype</h4>
      
      
        <p className="Outfit-Light" style={{ margin: "4px",
        marginLeft: "24px",
        marginRight: "24px",
        marginTop: "12px",}}>  
        The final 1:1 prototype is printed from PLA. Please remember that the 
        bottle is only an example concept, and therefore no explicit tests were 
        made regarding the durability, removable parts, connections between them, 
        or the parts that would need to be placed inside the culture medium while 
        the mycelium is growing to hold.
        
         </p></div>

         <FlexBreak />


<img src="./Bottle/20220915_162130 (2).jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "1400px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)", 
  margin: "4px",
  marginTop: "24px",
}} /> 

<img src="./Bottle/20220915_160458 (3).jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "1400px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)", 
  margin: "4px",
  marginTop: "24px",
}} /> 

<img src="./Bottle/view2.jpg" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: "1500px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(100% - 8px)", 
  margin: "4px"
}} /> 

</div>;

 





const Fungi = <div className="font-link" style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/basic 8-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/basic 3-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/txtr 6-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/txtr 5.1 band-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/txtr 4-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/txtr 2.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/basic 4-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>

<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/basic 5-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/basic 6-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/basic 7-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/basic 9-0180-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


<video
  autoPlay
  loop
  muted
  style={{ 
    flex: "1 1 auto", 
    maxWidth: "340px", 
    minWidth: "200px", 
    borderRadius: "8px", 
    width: "calc(15% - 8px)", 
    margin: "4px"
  }}
>
  <source src="/images/basic 10-min.mp4" type="video/mp4" />
  Your browser does not support the video tag.
</video>


  <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "430px", 
    minWidth: "300px", 
    flexDirection: "column", 
    alignItems: "flex-start",  
    margin: "4px",
    marginLeft: "24px",
    marginRight: "24px",
    marginBottom: "24px",
    width: "calc(28% - 8px)", 
  
    }}>
  
  <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Framed Fluids</h4>
   
  
   <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
   "Framed Fluids" was my first attempt to get my hands on the Blender geometry node workflow. 
   In my opinion, this kind of workflow seems more suitable for creating abstract shapes than precise models, 
   at least for a beginner. Furthermore, I wanted to know how much effort I had to put into 
   creating just slightly different iterations in a pretty short amount of time. 
   I intend to implement geometry-node-based models into my workflow to make 
   quick iterations of products and artistic animations in the future.
 </p></div>
    

    </div>;


const Content11 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>


<img src="./Scoby/SCOBY FIN2 (16 von 24).png" alt="Fungi Studies" style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />

<img src="./Scoby/SCOBY FIN2 (4 von 24).png" alt="Fungi Studies" style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />

<img src="./Scoby/SCOBY FIN2 (18 von 24).png" alt="Fungi Studies" style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />


<img src="./Scoby/SCOBY FIN2 (19 von 24).png" alt="Fungi Studies" style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />


<img src="./Scoby/SCOBY FIN2 (20 von 24).png" alt="Fungi Studies" style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />


<img src="./Scoby/SCOBY FIN2 (21 von 24).png" alt="Fungi Studies" style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />


<img src="./Scoby/SCOBY FIN2 (22 von 24).png" alt="Fungi Studies" style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />


<img src="./Scoby/SCOBY FIN2 (23 von 24).png" alt="Fungi Studies" style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />


<img src="./Scoby/SCOBY FIN2 (24 von 24).png" alt="Fungi Studies" style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />


<img src="./Scoby/SCOBY FIN2 (1 von 1).png" alt="Fungi Studies"
 style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />


<img src="./Scoby/SCOBY FIN2 (3 von 24).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: " 350px", 
   minWidth: "200px", 
   borderRadius: "8px", 
   width: "calc(15% - 8px)", 
   margin: "4px"  
 }} />
 
 
 <img src="./Scoby/SCOBY FIN2 (13 von 24).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: " 350px", 
   minWidth: "200px", 
   borderRadius: "8px", 
   width: "calc(15% - 8px)", 
   margin: "4px"  
 }} />
 
 
 <img src="./Scoby/SCOBY FIN2 (14 von 24).png" alt="Fungi Studies" style={{ 
  flex: "1 1 auto", 
  maxWidth: " 350px", 
   minWidth: "200px", 
   borderRadius: "8px", 
   width: "calc(15% - 8px)", 
   margin: "4px"  
 }} />



<img src="./Scoby/SCOBY FIN2 (7 von 24).png" alt="Fungi Studies"
 style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />

<img src="./Scoby/SCOBY FIN2 (15 von 24).png" alt="Fungi Studies"
 style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />

<img src="./Scoby/SCOBY FIN2 (6 von 24).png" alt="Fungi Studies"
 style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />


<img src="./Scoby/SCOBY FIN2 (1 von 24).png" alt="Fungi Studies"
 style={{ 
 flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"  
}} />

<img src="./Scoby/SCOBY FIN2 (11 von 24).png" alt="Fungi Studies"
 style={{ 
  flex: "1 1 auto", 
 maxWidth: " 350px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(15% - 8px)", 
  margin: "4px"
}} />

<img src="./Scoby/1 von 1.png" alt="Fungi Studies"
 style={{ 
  flex: "1 1 auto", 
  maxWidth: "477px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(25% - 8px)", 
  margin: "4px"
}} />

<img src="./Scoby/SCOBY FIN2 (17 von 24).png" alt="Fungi Studies"
 style={{ 
  flex: "1 1 auto", 
  maxWidth: "477px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(25% - 8px)", 
  margin: "4px"
}} />

<img src="./Scoby/SCOBY FIN2 (8 von 24).png" alt="Fungi Studies"
 style={{ 
  flex: "1 1 auto", 
  maxWidth: "477px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(25% - 8px)", 
  margin: "4px"
}} />

<img src="./Scoby/SCOBY FIN2 (12 von 24).png" alt="Fungi Studies"
 style={{ 
  flex: "1 1 auto", 
  maxWidth: "477px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(25% - 8px)", 
  margin: "4px"
}} />

<FlexBreak style={{

  }}/>

<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "487px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(25% - 8px)", 
  border: "1px solid black", 
  borderRadius: "8px", 
  margin: "24px 4px 24px 4px",
  padding: "4px",
  paddingLeft: "18px",
  paddingRight: "18px",
  }}>


  

<h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px", marginBottom: "0px"}}>What is SCOBY?</h4>


  <p className="Outfit-Light" >  
  SCOBY (Symbiotic Culture of Bacteria and Yeast) is a 
  so-called "tea fungus culture" made up of bacteria 
  and yeast, held together by cellulose. Most people 
  are probably familiar with the drink Kombucha, which 
  is fermented by the SCOBY during its growth in tea 
  enriched with sugar. Kombucha has been known in 
  Asia for over 2000 years as a beverage with healing 
  properties. The yeast converts the sugar into alcohol, 
  which the microorganisms in Kombucha process along 
  with the nutrients of the tea into organic acids, 
  dietary fibers, vitamins, and some other components. 
  During this process, the SCOBY requires oxygen, 
  which is why it floats on the surface. As it is 
  supplied with nutrients, thin layers form on the 
  SCOBY, which grow together and make it thicker. When dried, SCOBY can be used, for example,  
  as an easily produced and biodegradable leather substitute.
   </p></div>




   <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "487px", 
    minWidth: "300px", 
    flexDirection: "column", 
    alignItems: "flex-start",   
    width: "calc(25% - 8px)", 
    border: "1px solid black", 
    borderRadius: "8px", 
    margin: "24px 4px 24px 4px",
    padding: "4px",
    paddingLeft: "18px",
    paddingRight: "18px",
    }}>

  
  
  
  
    <p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px", marginBottom: "0px"}} >  
    The scoby experiments and resulting samples were actually a result of the "Fungi Studies" 
    experiment. Therefore, the evaluation is pretty similar. The focus we had when analyzing 
    the scoby samples was actually not only on the way it can be worked during but also after 
    the growth process. In contrast to mycelium, scoby can be modified even further after the 
    growth process by stirring, dying, or heat treatment. Another major advantage against 
    mycelium when growing scoby was that it was not necessary to sterilize all the tools 
    and vessels that are used for preparing and processing the scoby samples.
     </p></div>


     
 <div style={{
  flex: "1 1 auto",
  maxWidth: " 750px", 
      minWidth: "200px", 
      borderRadius: "8px", 
      width: "calc(50% - 8px)",
  margin: "4px",
  marginBottom: "24px",
  marginTop: "24px",
  display: "flex",
  flexDirection: "column",
   
}}>
  <div style={{
    width: "100%",
    paddingBottom: "100%",
  
    position: "relative",
  }}>
    <img 
      src="./Scoby/20220626_133108 (2).jpg" 
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "8px",
    
      }}
    />
  </div>
</div>



<div style={{
  flex: "1 1 auto",
  maxWidth: " 750px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)",
  margin: "4px",
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column",
   
}}>
  <div style={{
    width: "100%",
    paddingBottom: "100%",
    position: "relative",
  }}>
    <img 
      src="./Scoby/20220911_133940.jpg" 
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "8px",
    
      }}
    />
  </div>
</div>


   <div style={{  
    display: "flex",
    flex: "1 1 auto",  
    maxWidth: "487px", 
    minWidth: "300px", 
    flexDirection: "column", 
    alignItems: "flex-start",   
    width: "calc(25% - 8px)", 
    border: "1px solid black", 
    borderRadius: "8px", 
    margin: "4px 4px 24px 4px",
    padding: "4px",
    paddingLeft: "18px",
    paddingRight: "18px",
    }}>
  
  
    
  
  <h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px", marginBottom: "0px"}}>Preparation</h4>
  
  
    <p className="Outfit-Light" >  
    Scoby can easily be grown at home. However, there were some 
    circumstances that needed to be considered before we started 
    growing our first cultures. For example, the temperature. 
    22 °C to 26 °C are considered to be the best temperatures 
    for breeding healthy scoby. Below this temperature, 
    the growth takes longer; above, it grows significantly faster. 
    Next are the vessels in which the scoby is cultivated. They 
    should be made of stainless steel, enamel, glass, or possibly 
    plastic. Even if the tools and vessels to work with don't need 
    to be sterilized, they need to be cleaned properly with dish soap, 
    and afterwards everything should be rinsed with water until no 
    residues of the soap remain.
     </p></div>
  
  
  
  
     <div style={{  
      display: "flex",
      flex: "1 1 auto",  
      maxWidth: "487px", 
      minWidth: "300px", 
      flexDirection: "column", 
      alignItems: "flex-start",   
      width: "calc(25% - 8px)", 
      border: "1px solid black", 
      borderRadius: "8px", 
      margin: "4px 4px 24px 4px",
      padding: "4px",
      paddingLeft: "18px",
      paddingRight: "18px",
      }}>
  
    
    
    
    
      <p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px", marginBottom: "0px"}}>  
      The vessel in which the scoby grows should always be well covered with 
      a breathable cloth to protect against contamination. In the summer, 
      this also protects against fruit fly larvae that can settle in the scoby. 
      In addition, the tea used must be organic. The scoby can't process flavored 
      teas and grows poorly, or, 
in the worst case, not at all on them. The suitable types of tea are 
nevertheless very diverse. Even coffee is suitable as a nutrient medium. 
Some materials are needed to grow it anyway:
   <br /> 
   • Cooking pot<br /> 
• Scale<br /> 
• Containers (various)<br /> 
• Kitchen towels or similar<br /> 
• Whisk<br /> 
• Rubber bands<br /> 
• (Brown) Sugar<br /> 
• Water<br /> 
• Tea<br /> 
• Tea bags<br /> 
• Living SCOBY culture with Kombucha.<br /> 

       </p></div>

<FlexBreak />

       <div style={{  
        display: "flex",
        flex: "1 1 auto",  
        maxWidth: "487px", 
        minWidth: "300px", 
        flexDirection: "column", 
        alignItems: "flex-start",   
        width: "calc(25% - 8px)", 
        border: "1px solid black", 
      borderRadius: "8px", 
      margin: "4px 4px 24px 4px",
      padding: "4px",
      paddingLeft: "18px",
      paddingRight: "18px",
      }}>
  
    
    
    
    
      <p className="Outfit-Light" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px", marginBottom: "0px"}} >  
      The samples made from puréed scoby were 
      created as an experiment to make something 
      out of the remnants of the scobys we inevitably 
      produced. Some of the ingredients for these material 
      samples were taken from traditional bioplastic recipes. 
      The necessary equipment was as follows:
        <br />
• some water<br />
• Scoby cultures (preferably several 100 g)<br />
• Binding agents (Agar Agar, Gelatin, Alginate)<br />
• Food coloring<br />
• Hand blender<br />
• Container for the Scoby (and for pureeing)<br />
• Oven-proof, flat containers<br />
• Glycerin<br />

</p></div>


<div style={{
  flex: "1 1 auto",
  maxWidth: " 750px", 
  minWidth: "200px", 
  borderRadius: "8px", 
  width: "calc(25% - 8px)",
  margin: "4px",
  marginBottom: "24px",
  display: "flex",
  flexDirection: "column",
   
}}>
  <div style={{
    width: "100%",
    paddingBottom: "100%",
    position: "relative",
  }}>
    <img 
      src="./Scoby/20220628_202948.jpg" 
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius: "8px",
    
      }}
    />
  </div>
  
</div>
  

       <div style={{
        flex: "1 1 auto",
        maxWidth: " 1200px", 
        minWidth: "200px", 
        borderRadius: "8px", 
        width: "calc(50% - 8px)",
        margin: "4px",
        marginBottom: "24px",
        display: "flex",
        flexDirection: "column",
         
      }}>
        <div style={{
          width: "100%",
          paddingBottom: "100%",
          position: "relative",
        }}>
          <img 
            src="./Scoby/20220628_193059.jpg" 
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "8px",
          
            }}
          />
        </div>
      </div>

      

      <FlexBreak />
      

      <div style={{
        flex: "1 1 auto",
        maxWidth: " 1500px", 
        minWidth: "200px", 
        borderRadius: "8px", 
        width: "calc(100% - 8px)",
        margin: "4px",
        marginBottom: "24px",
        display: "flex",
        flexDirection: "column",
         
      }}>


        <div style={{
          width: "100%",
          paddingBottom: "100%",
          position: "relative",
        }}>
          <img 
            src="./Scoby/comp.jpg" 
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "8px",
          
            }}
          />
        </div>
      </div>

      <div style={{  
        display: "flex",
        flex: "1 1 auto",  
        maxWidth: "750px", 
        minWidth: "300px", 
        flexDirection: "column", 
        alignItems: "flex-start",   
        width: "calc(50% - 8px)", 
        border: "1px solid black", 
        borderRadius: "8px", 
        margin: "4px 4px 24px 4px",
        padding: "4px",
        paddingLeft: "18px",
        paddingRight: "18px",
        }}>
      
      
        
      
      <h4 className="Outfit-Medium" style={{ color: 'rgb(40, 40, 40)', marginTop: "10px", marginBottom: "0px"}}>Further exploration</h4>
      
      
        <p className="Outfit-Light">  
        Even though the scoby samples were a "side product" of the "Fungi Studies" Project, 
        the material seemed to have much more potential for exploration than mycelium. 
        Therefore, we initiated "Project SCOBY" with one of our professors, Cora Gebauer, 
        to explore ways of growing scoby and the possible use cases.
         </p></div>
      
      
         <div style={{  
          display: "flex",
          flex: "1 1 auto",  
          maxWidth: "750px", 
          minWidth: "300px", 
          flexDirection: "column", 
          alignItems: "flex-start",   
          width: "calc(50% - 8px)", 
        
          }}>
        
        
          <p className="Outfit-Light" style={{
          margin: "4px",
          marginLeft: "24px",
          marginRight: "24px",}} >  
           </p></div>
      


</div>;


const Content12 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>



<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "800px", 
  minWidth: "200px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(45% - 8px)", 
  margin: "4px",
  marginLeft: "24px",
  marginRight: "8px",
  marginTop: "16px"
  }}>

<p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "0px" ,marginLeft: "0px"}}>  
My focus in design is mainly on material-based designs and concept design.
The radar chart on the right shows my focus on these disciplines.
To see the evaluation of the single disciplines, hover over the chart.
</p></div>

<div style={{ 
  flex: "1 1 auto",
  maxWidth: "800px", 
  minWidth: "330px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)",
  margin: "4px",
  marginBottom: "24px",
  marginTop: "24px",
  display: "flex",
  flexDirection: "column",
  position: "relative", 
}}>
  <MyResponsiveRadar data={data} />
</div>

<FlexBreak/ >


<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "800px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(50% - 8px)", 
  marginTop:"24px"
  }}>

<h4 className="Outfit-Medium"  style={{ color: '#FFFFFF', 
marginTop: "20px",
marginBottom: "0px",
margin: "4px",
marginLeft: "24px",
marginRight: "24px",}}>3D Modeling </h4>

 <p  className="Outfit-Light" style={{ color: '#FFFFFF', margin: "4px",
marginLeft: "24px",
marginRight: "24px",
marginTop: "12px",}}>
Depending on what I try to achieve, I use different programs. 
I commonly use Fusion 360 for more simple product modelling and 3D printable models, 
Keyshot for the renderings and animations of these models.
 Blender is used for more complex models and more "artistic" approaches. 
 I just started using Unreal Engine 5/5.1/5.2 for creating VR experiences 
 and fast, high quality animations. 
</p></div>


<div style={{ 
  flex: "1 1 auto",
  maxWidth: "800px", 
  minWidth: "330px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)",
  margin: "4px",
  marginBottom: "24px",
  marginTop: "24px",
  display: "flex",
  flexDirection: "column",
  position: "relative",  
}}>
  <MyResponsivePie data={data} />
</div>

<FlexBreak/ >



<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "800px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(50% - 8px)", 
  marginTop:"24px"
  }}>

<h4 className="Outfit-Medium"  style={{ color: '#FFFFFF', 
marginTop: "20px",
marginBottom: "0px",
margin: "4px",
marginLeft: "24px",
marginRight: "24px",}}>Craftsmanship Skills</h4>

 <p  className="Outfit-Light" style={{ color: '#FFFFFF', margin: "4px",
marginLeft: "24px",
marginRight: "24px",
marginTop: "12px",}}>
I may not have many craft-related projects in my portfolio, 
but I’ve honed diverse skills through various internships 
and personal projects since my youth. My experience includes 
crafting furniture, jewelry, and everyday items. I’m versed 
in working with wood, machinery like bandsaws and lathes, 
and metalwork, such as soldering and sheet metal bending. 
I have basic skills in arc welding and techniques like etching 
Damascus steel. 

My repertoire of craft skills includes experience in screen printing, 
basic blacksmithing, and the use of molding silicone, plaster, and 
epoxy resin. Upon request, I can share images of my 
lesser-documented craftwork.
</p></div>


<div style={{ 
  flex: "1 1 auto",
  maxWidth: "800px", 
  minWidth: "330px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)",
  margin: "4px",
  marginBottom: "24px",
  marginTop: "24px",
  display: "flex",
  flexDirection: "column",
  position: "relative",  
}}>
  <MyResponsivePie4 data={data} />
</div>

<FlexBreak/ >


<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "800px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(50% - 8px)", 
  marginTop:"24px"
  }}>

<h4 className="Outfit-Medium"  style={{ color: '#FFFFFF', 
marginTop: "20px",
marginBottom: "0px",
margin: "4px",
marginLeft: "24px",
marginRight: "24px",}}>Graphic work</h4>


<p  className="Outfit-Light" style={{ color: '#FFFFFF', margin: "4px",
marginLeft: "24px",
marginRight: "24px",
marginTop: "12px",}}>
I use the adobe Cloud for compositing Videos, creating print media and 
refining photographs. However, my focus in graphics is mostly related to layouting in Adobe Illustrator
and Camera Raw or Lightroom for picture processing. I like refining Sketches in Adobe Illustrator but 
I don't have a drawing tablet, which is why I don't use it to visualize concept ideas that often.
</p></div>

<div style={{ 
  flex: "1 1 auto",
  maxWidth: "800px", 
  minWidth: "330px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)",
  margin: "4px",
  marginBottom: "24px",
  marginTop: "24px",
  display: "flex",
  flexDirection: "column",
  position: "relative",  
}}>
  <MyResponsivePie2 data={data} />
</div>


<FlexBreak/ >

<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "800px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(50% - 8px)", 
  marginTop:"24px"
  }}>


<h4 className="Outfit-Medium"  style={{ color: '#FFFFFF', 
marginTop: "20px",
marginBottom: "0px",
margin: "4px",
marginLeft: "24px",
marginRight: "24px",}}>Iteration/Realisation</h4>


<p  className="Outfit-Light" style={{ color: '#FFFFFF', margin: "4px",
marginLeft: "24px",
marginRight: "24px",
marginTop: "12px",}}>
I use Sketches and (paper)prototypes as a quick and simple 
Process to define proportions, ergonomics and aesthetics of 
objects in 2D and 3D. If I need to produce more complex prototypes for 
testing in regard to durability or other specific properties, 
I use my own FDM printer to create prototypes, I'm currently 
getting started with SLA printing. Tests for materials that I 
can't work with at home are realised at the Workshop in the university. 
</p></div>

<div style={{ 
  flex: "1 1 auto",
  maxWidth: "800px", 
  minWidth: "330px", 
  borderRadius: "8px", 
  width: "calc(50% - 8px)",
  margin: "4px",
  marginBottom: "24px",
  marginTop: "24px",
  display: "flex",
  flexDirection: "column",
  position: "relative",  
}}>
  <MyResponsivePie3 data={data} />
</div>

<FlexBreak/ >

<p className="Outfit-Light" style={{ color: "#ececec"}} ></p>
</div>;

const Content13 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "cLeft", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>

<RandomVideo style={{
  flex: "1 1 auto",
  maxWidth: "1500px",
  minWidth: "300px",
  borderRadius: "8px",
  width: "calc(100% - 8px)",
  margin: "4px"
}} />

<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "487px", 
  minWidth: "300px", 
  flexDirection: "column", 
  alignItems: "flex-start",   
  width: "calc(50% - 8px)", 
  marginTop:"24px"
  }}>

<h4 className="Outfit-Medium" style={{ color: '#FFFFFF', marginTop: "20px",
marginBottom: "0px",
margin: "4px",
marginLeft: "24px",
marginRight: "24px"}}></h4>


<p className="Outfit-Light" style={{ color: '#FFFFFF', margin: "4px",
marginLeft: "24px",
marginRight: "24px",
marginTop: "12px",}} >
My Name is Karl Friedrich Oetken, I'm 24 Years old <br/>
and I currently study Industrial design at the h2 in Magdeburg. <br/> <br/>
I'm fascinated by nature, its shapes and structures, 
sustainability-oriented design studies, 3D printing, animation, 
and emerging Technologies such as Ai tools, blockchain technology 
and many other things. <br/><br/>
My approach In design is to create 
concepts for a more environmentally friendly and democratic future
by searching for unconventional views and solutions to solve problems I encounter. <br/><br/>

You Can Contact me via: <br/>
<a href="mailto:Karl-oetken@gmx.de" style={{ color: '#FFFFFF' }}>karl-oetken@gmx.de</a><br/>
</p></div>



<p className="Outfit-Light" style={{ color: "#ececec"}} ></p>
</div>;



const Content14 = <div className="font-link"style={{ 
  display: "flex", 
  flexWrap: "wrap", 
  justifyContent: "Left", 
  marginLeft: "-0.5px",
  marginRight: "-0.5px"
}}>


<div style={{  
  display: "flex",
  flex: "1 1 auto",  
  maxWidth: "1500px", 
  minWidth: "50px", 
  flexDirection: "column", 
  alignItems: "flex-start",  
  margin: "4px",
  marginLeft: "24px",
  marginRight: "24px",
  marginBottom: "24px",
  width: "calc(100% - 8px)", 

  }}>

<h2 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>1. Datenschutz auf einen Blick</h2>
 
<h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Allgemeine Hinweise</h4>
 

 <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
 Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
 passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
 persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
 Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>



 <h2 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Datenerfassung auf dieser Website</h2>
 
<h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
 

 <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
 Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
 können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.
    </p>
 
    <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Wie erfassen wir Ihre Daten?</h4>
     
    
     <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
     Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
Daten handeln, die Sie in ein Kontaktformular eingeben.<br/><br/>
Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
        </p>
    

        <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Wofür nutzen wir Ihre Daten?</h4>
     
    
        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
        Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>

        <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
     
    
        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
          gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
          Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
          können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
          bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden
          </p>


      <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Analyse-Tools und Tools von Drittanbietern</h4>
     
    
        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
        allem mit sogenannten Analyseprogrammen.<br/><br/>
        Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden
        Datenschutzerklärung.</p>






        <h2 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>2. Hosting</h2>
 

        
       <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
       Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
       </p>
       <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Externes Hosting</h4>
 

         <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
               Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden,
               werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen,
               Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe
               und sonstige Daten, die über eine Website generiert werden, handeln.<br/><br/>
               Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und
               bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
               Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
               Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
               Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung
               von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im
               Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.<br/><br/>
               Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
               Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.<br/><br/>
               Wir setzen folgende(n) Hoster ein:<br/><br/>
               Name des Webhosting Anbieters: Netlify<br/>
               Name des dienstanbietenden Unternehmens: Netlify, Inc <br/>
               Addresse: 44 Montgomery Street, Suite 300, San Francisco, CA 94104<br/>
               Kontaktdetails: <a href="https://privacy@netlify.com" style={{ color: '#FFFFFF' }}>privacy@netlify.com</a><br/>
               <br/>
               Durch Netlify werden ihre Daten unter anderem auch in den USA verarbeitet. 
               Wir weisen Sie hiermit darauf hin, dass nach Meinung des Europäischen Gerichtshofs 
               zum aktuellen Zeitpunkt kein angemessenes Schutzniveao für den Datentransfer in die USA besteht. 
               Dieser Umstand kann mit verschiedenen Risiken für die Sicherheit und 
               Rechtmäßigkeit der Datenverarbeitung einhergehen.<br/><br/>
               Als Grundlage der Datenverarbeitung bei Empfängern mit Sitz in Drittstaaten 
               (außerhalb der Europäischen Union, Island, Lichtenstein, Norwegen und 
               insbesondere in den USA) oder einer Datenweitergabe in diese Drittstaaten 
               verwendet Netlify sogenannte 
               Standardvertragsklauseln (= Art 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln 
               (Standard Contractual Clauses - SCC) sind von der EU-Kommission bereitgestellte 
               Mustervorlagen und sollen sicherstellen, dass ihre Daten auch dann den europäischen 
               Datenschutzstandards entsprechen, wenn diese in Drittländer (wie die USA) überliefert und dort gespeichert werden.<br/><br/>
               Durch die Klauseln verpflichtet sich Netlify, bei der Verarbeitung Ihrer relevanten Daten, das europäische 
               Datenschutzniveao einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden.
               Diese Klauseln basieren auf den Durchführungsbeschluss der EU-Kommission.<br/> sie finden den Beschluss und die 
               entsprechenden Standardvertragsklauseln u.a. hier:
               <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" style={{ color: '#FFFFFF' }}>https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
               <br/><br/>
               Netlify hält einen Vertrag über die Auftragsverarbeitung gemäß Art. 28 DSGVO bereit, 
               der als datenschutzrechtliche Grundlage für unsere Kundenbeziehung zu Netlify fungiert.
               Dieser verweist inhaltlich auf die EU-Standardvertragsklauseln.<br/>
               Sie finden ihn hier: https://www.netlify.com/pdf/netlify-dpa.pdf
               <a href="https://www.netlify.com/pdf/netlify-dpa.pdf" style={{ color: '#FFFFFF' }}>https://www.netlify.com/pdf/netlify-dpa.pdf</a>
               <br/><br/>
               Mehr über die Daten, die durch die Verwendung von Netlify verarbeitet werden, erfahren 
               Sie in der Privacy Policy auf 
               <a href="https://www.netlify.com/privacy/?tid=331687894165" style={{ color: '#FFFFFF' }}>https://www.netlify.com/privacy/?tid=331687894165</a>
               </p>

       <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Auftragsverarbeitung</h4>


       <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
       Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
      geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
      gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren
      Weisungen und unter Einhaltung der DSGVO verarbeitet.</p>


      <h2 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>3. Allgemeine Hinweise und Pflichtinformationen</h2>

      <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Datenschutz</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
        personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
        dieser Datenschutzerklärung.<br/><br/>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
        Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
        und zu welchem Zweck das geschieht.<br/><br/>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
        Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.</p>

        <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Hinweis zur verantwortlichen Stelle</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:<br/><br/>
        Karl Oetken<br/>
        Hinrichsenstraße 24<br/>
        04105 Leipzig<br/>
        Deutschland<br/><br/>
        Telefon: 015151823990<br/>
        E-Mail: <a href="mailto:KarlOetken.Design@gmx.de" style={{ color: '#FFFFFF' }}>KarlOetken.Design@gmx.de</a><br/><br/><br/>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
        die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
        entscheidet.</p>

        <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Speicherdauer</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.</p>



<h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Allgemeine
 Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser
Website
</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art.
49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in
Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich
auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre
Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden
Absätzen dieser Datenschutzerklärung informiert.</p>


<h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
Datenverarbeitung bleibt vom Widerruf unberührt.</p>


<h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
Direktwerbung (Art. 21 DSGVO)
</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).<br/><br/>
WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
NACH ART. 21 ABS. 2 DSGVO).
</p>



<h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Beschwerderecht bei der zuständigen Aufsichtsbehörde
</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
</p>


<h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Recht auf Datenübertragbarkeit
</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
        automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
        aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
        verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

        <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Auskunft, Berichtigung und Löschung</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
        Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
        Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
        zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p>

        <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Recht auf Einschränkung der Verarbeitung</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
folgenden Fällen:<br/><br/>
Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>
Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der 
Löschung die Einschränkung der Datenverarbeitung verlangen.<br/>
Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.<br/>
Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
zu verlangen.<br/><br/>
Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
eines Mitgliedstaats verarbeitet werden.</p>




<h2 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>4. Plugins und Tools</h2>

      <h4 className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>Google Fonts (lokales Hosting)</h4>


        <p className="Outfit-Light" style={{ color: '#FFFFFF', marginTop: "16px" ,marginBottom: "0px"}}>
        Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Google Fonts, die von Google
bereitgestellt werden. Die Google Fonts sind lokal installiert. Eine Verbindung zu Servern von Google findet
dabei nicht statt.<br/><br/>
Weitere Informationen zu Google Fonts finden Sie unter<br/>
<a href="https://developers.google.com/fonts/faq" style={{ color: '#FFFFFF' }}>https://developers.google.com/fonts/faq</a><br/>
und in der Datenschutzerklärung von Google:<br/>
<a href="https://policies.google.com/privacy?hl=de." style={{ color: '#FFFFFF' }}>https://policies.google.com/privacy?hl=de.</a>
<br/><br/>

Quelle:<br/>
<a href="https://www.e-recht24.de" style={{ color: '#FFFFFF' }}>https://www.e-recht24.de</a>
</p>
    </div>
    </div>
   



const widgetNames = {
  a: <div><span style={{ Color: 'rgb(100,255,25)',fontSize: 25 }}>{Projecta}</span></div>,


  b: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectb}</span></div>,

  c: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectc}</span></div>,

  d: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectd}</span></div>,

  e: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projecte}</span></div>,

  f: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectf}</span></div>,

  g: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectg}</span></div>,

  h: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projecth}</span></div>,

  i: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projecti}</span></div>,

  j: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectj}</span></div>,

  k: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectk}</span></div>,
  
  l: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectl}</span></div>,

  m: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectm}</span></div>,

  n: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projectn}</span></div>,

  o: <div><span style={{ color: 'rgb(25,25,25)', fontSize: 25 }}>{Projecto}</span></div>

};


const widgetcontent = {
  a: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15}}>{Content5}</span></div>,


  b: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content2}</span></div>,

  c: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content4}</span></div>,

  d: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content8}</span></div>,

  e: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content7}</span></div>,

  f: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15}}>{Fungi}</span></div>,

  g: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content3 }</span></div>,

  h: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content1}</span></div>,

  i: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content6}</span></div>,

  j: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content9}</span></div>,

  k: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content10}</span></div>,

  l: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content11}</span></div>,

  m: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content12}</span></div>,

  n: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content13}</span></div>,

  o: <div><span style={{ color: 'rgb(55,55,55)', fontSize: 15 }}>{Content14}</span></div>
};

const widgetinfo= {
  a:"Semester: 6. | guidance: Prof. Marion Meyer (Dipl.Des.) | Date: 2022",

  b:"Semester: 5. | guidance: M.A. Cora Gebauer | Date: 2021-22",
 
  c:"Semester: 3. | guidance: M.A. Cora Gebauer | Date: 2020-21",

  d:"Semester: none | guidance: none | Date: 2023",

  e:"Semester: none | guidance: none | Date: since 2022",

  f:"Semester: none | guidance: none | Date: 2022",

  g:"Semester: 3. | guidance: Dipl.Des. Christian Felsch von Wild | Date: 2020-21",

  h:"Semester: 2. | guidance: M.A. Cora Gebauer | Date: 2020",

  i:"Semester: 3. | guidance: Prof. Marion Meyer (Dipl.Des.) | Date: 2020-21",

  j:"Semester: none | guidance: none | Date: 2021",

  k:"Semester: 6. | guidance: Prof. Marion Meyer (Dipl.Des.) | Date: 2022",

  l:"Semester: 6. | guidance: Prof. Marion Meyer (Dipl.Des.) | Date: 2022",

  m:"Semester: 8. | guidance: Dipl. Ing. Matthias Schützelt | Date: 2023",

  n:"Semester: 8. | guidance: Dipl. Ing. Matthias Schützelt | Date: 2023",

  //o:"",
};


export default function Widget({ id, onRemoveItem, backgroundColor, deleteButtonColor,infoButtonColor}) {
  const classes = useStyles();

  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleRemoveClick = () => {
    onRemoveItem(id);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <Card className={classes.root} style={{ backgroundColor: backgroundColor }}>
    
      <div className={classes.header}>

      <Tooltip
      title={widgetinfo[id]} 
      placement="top"
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      open={openTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      disableklickListener
    >
      <IconButton
        aria-label="info"
        className={classes.infoButton}
        onMouseOver={handleTooltipOpen}
        onMouseOut={handleTooltipClose}
        style={{ color: infoButtonColor }}
      >
        <InfoIcon />
      </IconButton>
    </Tooltip>

      <IconButton
      aria-label="delete"
      className={classes.closeButton}
      onClick={handleRemoveClick}
      style={{ color: deleteButtonColor }}
    >
      <CloseIcon fontSize="small" />


    </IconButton>
        <Typography variant="h6" gutterBottom>
          {widgetNames[id]}
        </Typography>

        <div className={classes.spacer} />
      
      </div>
      <Typography variant="h6" gutterBottom>
        {widgetcontent[id]}
      </Typography>
      <div className={classes.body} />
    </Card>
  );
}