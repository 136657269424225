import React, { useState, useEffect, useRef } from 'react';
import { ResponsivePie } from "@nivo/pie";
import ResizeObserver from "resize-observer-polyfill";



const data = [
  {
    "id": "Fusion 360",
    "label": "Fusion 360",
    "value": 30,
    "color": "hsl(82, 70%, 50%)"
  },
  {
    "id": "Blender",
    "label": "Blender",
    "value": 25,
    "color": "hsl(325, 70%, 50%)"
  },
  {
    "id": "Keyshot",
    "label": "Keyshot",
    "value": 20,
    "color": "hsl(203, 70%, 50%)"
  },
  {
    "id": "Ue 5",
    "label": "Ue5",
    "value": 15,
    "color": "hsl(61, 70%, 50%)"
  },
  {
    "id": "Other",
    "label": "Other",
    "value": 10,
    "color": "hsl(221, 70%, 50%)"
  }
];
const MyResponsivePie = ({ data }) => {
  const [isLegendVisible, setLegendVisible] = useState(true);
  const chartContainerRef = useRef();

  useEffect(() => {
    const ro = new ResizeObserver(entries => {
      const { width } = entries[0].contentRect;
      setLegendVisible(width > 400);  
    });
    if (chartContainerRef.current) {
      ro.observe(chartContainerRef.current);
    }
    return () => {
      if (chartContainerRef.current) {
        ro.unobserve(chartContainerRef.current);
      }
    };
  }, [chartContainerRef]);

  return (
    <div 
      ref={chartContainerRef}
      style={{
        position: "absolute",  
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
    <ResponsivePie
    data={data}
    margin={{ top: 40, right: 90, bottom: 90, left: 100 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={4}
    activeOuterRadiusOffset={8}
    colors={{ scheme: 'reds' }}
    borderColor={{
        from: 'color',
        modifiers: [
            [
                'darker',
                0.2
            ]
        ]
    }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#ffffff"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor="#ffffff"
    legends={isLegendVisible ? [
        {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 25,
            translateY: 56,
            itemsSpacing: 0,
            itemWidth: 94,
            itemHeight: 18,
            itemTextColor: '#999',
            itemDirection: 'left-to-right',
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
                {
                    on: 'hover',
                    style: {
                        itemTextColor: '#000'
                    }
                }
            ]
        }
    ] : []}
  />
</div>
);
};
export default function App() {
  return (
    <div style={{ width: 550, height: 550  }}>
      <MyResponsivePie data={data} />
    </div>
  );
}

