import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider, createMuiTheme } from "@material-ui/core";
import Content from "./Content";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./footer";
import "./styles.css";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";

const originalItems = ["a" , "b", "c", "d", "e", "f", "g", "h", "i", "j","k","l",/*"m",*/"n"];

const initialLayouts = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33, maxH: 2000 },
    { i: "b", x: 0, y: 900, w: 8, h: 87, minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 4, y: 200, w: 8, h: 95, minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 8, y: 1000, w: 4, h: 42, minW: 2, maxW: 12, minH: 30,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 9, h: 144, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 0, y: 100, w: 4, h: 109, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 8, h: 47, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 5, y: 600, w: 7, h: 50, minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 0, y: 800, w: 5, h: 50, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 8, y: 1200, w: 4, h: 32, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 9, y: 200, w: 3, h: 144, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 8, y: 10000, w: 4, h: 20, minW:2, minH: 15, maxW: 12,  maxH: 1600},
    { i: "n", x: 8, y: 500, w: 4, h: 47, minW:2, minH: 23, maxW: 12,  maxH: 1600},
  ]
};

const initialLayoutsPrivate = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33, maxH: 2000 },
    { i: "b", x: 8, y: 300, w: 4, h: 49, minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 100, w: 4, h: 67 ,minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 0, y: 0, w: 12, h: 42 ,minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 9, h: 144,minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 9, y: 0, w: 3, h: 144, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 4, y: 0, w: 4, h: 53, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 5, h: 27, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 4, y: 200, w: 4, h: 32, minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 0, y: 500, w: 12, h: 24, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 0, y: 300, w: 4, h: 32, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 0, y: 600, w: 12, h: 33, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 5, y: 1000, w: 3, h: 27, minW:2, minH: 23, maxW: 12,  maxH: 1600},
    { i: "n", x: 0, y: 0, w: 3, h: 27, minW:2, minH: 23, maxW: 12,  maxH: 1600}
  ]
};


const initialLayoutsMaterial= { 
  lg: [
    { i: "a", x: 0, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33,  maxH: 1600 },
    { i: "b", x: 8, y: 0, w: 4, h: 48,  minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 200, w: 4, h: 56 , minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 4, y: 200, w: 8, h: 42 , minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 8, h: 58, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 4, y: 0, w: 4, h: 53, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 8, y: 0, w: 4, h: 56 ,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 4, y: 200, w: 4, h: 44,  minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 8, y: 400, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 8, y: 300, w: 4, h: 32,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 0, y: 400, w: 12, h: 34, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 0, y: 500, w: 4, h: 40, minW:2, minH: 23, maxW: 12,  maxH: 1600},
    { i: "n", x: 5, y: 500, w: 3, h: 27, minW:2, minH: 23, maxW: 12,  maxH: 1600}

  ]
};



const initialLayoutsProduct = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33,  maxH: 1600 },
    { i: "b", x: 7, y: 0, w: 5, h: 59,  minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 0, w: 7, h: 85, minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 4, y: 200, w: 8, h: 42 , minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 8, h: 58, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 0, y: 100, w: 7, h: 49, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 4, h: 41 ,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 7, y: 0, w: 5, h: 37,  minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 8, y: 400, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 7, y: 300, w: 5, h: 38,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 4, y: 400, w: 8, h: 33, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 4, y: 500, w: 8, h: 40, minW:2, minH: 37, maxW: 12,  maxH: 1600},
    { i: "n", x: 0, y: 500, w: 4, h: 40, minW:2, minH: 39, maxW: 12,  maxH: 1600}

  ]
};

const initialLayoutsAbout = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33,  maxH: 1600 },
    { i: "b", x: 8, y: 0, w: 4, h: 48,  minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 200, w: 4, h: 56 , minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 4, y: 200, w: 8, h: 42 , minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 8, h: 58, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 4, y: 0, w: 4, h: 53, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 4, h: 41 ,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 4, y: 200, w: 4, h: 44,  minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 8, y: 400, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 8, y: 300, w: 4, h: 32,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 4, y: 400, w: 8, h: 33, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 4, y: 500, w: 8, h: 47, minW:2, minH: 39, maxW: 12,  maxH: 1600},
    { i: "n", x: 0, y: 0, w: 4, h: 47, minW:2, minH: 39, maxW: 12,  maxH: 1600},
    { i: "o", x: 0, y: 500, w: 12, h: 300, minW:2, minH: 39, maxW: 12,  maxH: 1600}
  ]
};

const initialLayoutsPrivacy = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33,  maxH: 1600 },
    { i: "b", x: 8, y: 0, w: 4, h: 48, minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 200, w: 4, h: 56 , minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 4, y: 200, w: 8, h: 42 , minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 8, h: 58, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 4, y: 0, w: 4, h: 53, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 4, h: 41 ,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 4, y: 200, w: 4, h: 44, minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 8, y: 400, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 8, y: 300, w: 4, h: 32, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 4, y: 400, w: 8, h: 33, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 4, y: 500, w: 8, h: 45, minW:2, minH: 39, maxW: 12,  maxH: 1600},
    { i: "n", x: 0, y: 500, w: 4, h: 45, minW:2, minH: 39, maxW: 12,  maxH: 1600},
    { i: "o", x: 0, y: 500, w: 12, h: 250, minW:2, minH: 39, maxW: 12,  maxH: 1600}
  ]
};


const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  scrollButton: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
    color: 'black', // set color to black
    backgroundColor: '#ececec',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  }
}));

export default function App() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [scroll, setScroll] = useState(false);

  const [items, setItems] = useState(originalItems);
  const [layouts, setLayouts] = useState(getFromLS("layouts") || initialLayouts);

  const [dragEnabled, setDragEnabled] = useState(false);

  const toggleDrag = () => {
    setDragEnabled(!dragEnabled);
  };
  const handleClickOpen = () => {
    setIsOpen(true);
  };
  
  
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAllClick = () => {
    setActiveWidgets(["a","b","c","d","e","f","g","h","i","j","k","l","n"]);
  };

  const handleMailClick = () => {
    setActiveWidgets(["b","c","g","i","k"]);
    setLayouts(initialLayoutsProduct);
  };

  const handleMaterialClick = () => {
    setActiveWidgets(["h","l","a"]);
    setLayouts(initialLayoutsMaterial);
  };

  const handlePrivateClick = () => {
    setActiveWidgets(["d","e","f","j"]);
    setLayouts(initialLayoutsPrivate);
  };

  const handleAboutClick = () => {
    setActiveWidgets(["m","n"]);
    setLayouts(initialLayoutsAbout);
  };

  const handlePrivacyClick = () => {
    setActiveWidgets(["o"]);
    setLayouts(initialLayoutsPrivacy);
  };



  const [showOnlyWidgetB, setShowOnlyWidgetB] = useState(false);

const toggleShowOnlyWidgetB = () => {
  setShowOnlyWidgetB(!showOnlyWidgetB);
};
  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };
  const [activeWidgets, setActiveWidgets] = useState(originalItems);

  const onLayoutSave = () => {
    saveToLS("layouts", layouts);
  };

  const onRemoveItem = (itemId) => {
    setItems(items.filter((i) => i !== itemId));
    setActiveWidgets(activeWidgets.filter((i) => i !== itemId));
  };

  const onAddItem = (itemId) => {
    setItems([...items, itemId]);
  };
  
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = createMuiTheme({
    palette: {
      type: darkMode ? "dark" : "light"
    }
  });

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  function getFromLS(key) {
    let ls = {};
    if (global.localStorage) {
      try {
        ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) { }
    }
    return ls[key];
  }

  function saveToLS(key, value) {
    if (global.localStorage) {
      global.localStorage.setItem(
        "rgl-8",
        JSON.stringify({
          [key]: value
        })
      );
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Header
          dragEnabled={dragEnabled}
          setDragEnabled={setDragEnabled}
          toggleDrag={toggleDrag}
          handleDrawerToggle={handleDrawerToggle}
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          onLayoutSave={onLayoutSave}
          items={items}
          onRemoveItem={onRemoveItem}
          onAddItem={onAddItem}
          originalItems={originalItems}
           activeWidgets={activeWidgets}
  setActiveWidgets={setActiveWidgets}
        />
        <Sidebar handleDrawerClose={handleDrawerClose} open={open} 
        items={items}
          onRemoveItem={onRemoveItem}
        handleMailClick={handleMailClick} 
        handleMaterialClick={handleMaterialClick} 
        handleAllClick={handleAllClick}   
        handlePrivateClick={handlePrivateClick}
       handleAboutClick={handleAboutClick}
       /><main
          className={clsx(classes.content, {
            [classes.contentShift]: open
          })}
        >
          <div className={classes.drawerHeader} />
          <Content 
          activeWidgets={activeWidgets} 
          onRemoveItem={onRemoveItem} 
          dragEnabled={dragEnabled}
          setDragEnabled={setDragEnabled}
          toggleDrag={toggleDrag}/>
          <Footer 
          activeWidgets={activeWidgets}
          open={open} 
        items={items}
          handlePrivacyClick={handlePrivacyClick}/>
        </main>
        <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Outfit-Medium', 
        marginTop: "10px",
        marginBottom: "0px",
        margin: "4px",
        marginRight: "24px",
        color: "red"}} >{"IMPORTANT!"}</DialogTitle>
        <DialogContent>
          <DialogContentText  id="alert-dialog-description" style={{ fontFamily: 'Outfit-Light', fontSize: '15px',margin: "4px",
          marginRight: "24px", marginTop: "-10px"}}  >
            This website uses a <span style={{color: 'red'}}>react grid layout</span> and therefore works best on computers and laptops.<br/><br/>
            • However, if you look at it on your <span style={{color: 'red'}}>smartphone</span>, consider to click the 
            <span ><LockIcon fontSize="small" style={{color: 'red',verticalAlign: '-4px' }} /></span> symbol in 
            the header, to be able to  <span style={{color: 'red'}}>scroll through the website easily</span><br/><br/>
            • <span style={{color: 'red'}}>Resize</span> the single widgets via their <span style={{color: 'red'}}>handles</span> in their lower right corners <br/><br/>
            • <span style={{color: 'red'}}>Drag</span> and <span style={{color: 'red'}}>drop</span> projects by 
            <span style={{color: 'red'}}> grabing and moving them</span><br/><br/>
            This website is constantly refined and modified, bugs are fixed, 
            projects are replaced or refined and so on. Please tolerate 
            small problems you find and work around them.:)
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ fontFamily: 'Outfit-Medium', fontSize: '15px' }} autoFocus>
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
        <Zoom in={scroll} className={classes.scrollButton}>
          <Fab color="black" size="small" onClick={scrollToTop}>
            <KeyboardArrowUpIcon />
          </Fab>
        </Zoom>
      </div>
    </ThemeProvider>
  );
}