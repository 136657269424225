import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { withStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  popup: {
  
    padding: theme.spacing(2),
    backgroundColor: "#373737",
  },
  widgetText: {
    color: "#FFFFFF",
    fontFamily: "Outfit-Light",
    fontSize: "15px"
  },

}));

const widgetNames = {
  a: "Fungi Studies",
  b: "Remember Me",
  c: "WRK",
  d: "INK",
  e: "Skizzen",
  f: "Framed Fluids",
  g: "Rocking horse",
  h: "Layering",
  i: "Opener",
  j: "The Microcosm",
  k: "Fungi Bottle",
  l: "Scoby Samples",
  m: "My Workflow",
  n: "Me/Contact",
};


const CustomCheckbox = withStyles({
  root: {
    color: "#ffffff",
    '&$checked': {
      color: "#ffffff",
    },
  },
  checked: {},
})(Checkbox);


export default function AddList({
  items,
  onRemoveItem,
  onAddItem,
  originalItems,
  style
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (e) => {
    if (e.target.checked) {
      onAddItem(e.target.name);
    } else {
      onRemoveItem(e.target.name);
    }
  };
  return (
    <>
      <IconButton
        aria-label="add"
        onClick={handleClick}
        aria-describedby={id}
        style={style}
      >
        <AddCircleOutlineIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <div className={classes.popup}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.widgetText}>
              Select Projects
            </FormLabel>
            <FormGroup>
              {originalItems.map((i) => (
                <FormControlLabel
  control={
    <CustomCheckbox
      checked={items.includes(i)}
      onChange={handleChange}
      name={i}
      className={classes.checkbox}
    />
  }
  label={widgetNames[i]}
  key={i}
  classes={{ label: classes.widgetText }}
/>
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </Popover>
    </>
  );
}