import React from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MailIcon from "@material-ui/icons/Mail";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import "./styles.css";
import Typography from "@material-ui/core/Typography";



const drawerWidth = 240;

function CustomListItemText({ children, ...props }) {
  return (
    <ListItemText
      {...props}
      primary={<Typography style={{ fontSize: '14px', 
      fontFamily: 'Outfit-Light',
      color: '#FFFFFF' }}
      >
      {children}
      </Typography>}
    />
  );
} 
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: -1,
    backgroundColor: "#373737",

  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#373737",
    
  },
    drawerHeader: {
    display: "flex",
    alignItems: "right",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
   // backgroundColor: "rgb(172, 72, 92)",
    
  },

  listItemIcon: {
    color: '#ffffff',
  },

  ChevronLeftIcon: {
    color: '#ececec',
  }

}));

export default function ({ open, handleDrawerClose,
    handleMailClick, 
    handleMaterialClick, 
    handleAllClick, 
    handlePrivateClick,
    handleAboutClick,
     handlePrivacyClick}) {
  const classes = useStyles();


  
  return (
    
    <Drawer
  

      className={classes.drawer}
      variant="persistent"
      anchor="Right"
      open={open}
      
      classes={{
        paper: classes.drawerPaper
    
      }
    }
    
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon className={classes.ChevronLeftIcon}/>
        </IconButton>
      </div>
      <Divider />
      <div  className="">
        <List>
        <ListItem button onClick={handleAllClick}>
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardRoundedIcon />
            </ListItemIcon>
            <CustomListItemText>All</CustomListItemText>
          </ListItem>
          <ListItem button onClick={handleMailClick}>
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardRoundedIcon/>
            </ListItemIcon>
            <CustomListItemText>Product Design</CustomListItemText>  
          </ListItem>

          <ListItem button onClick={handleMaterialClick}>
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardRoundedIcon/>
            </ListItemIcon>
            <CustomListItemText>Material</CustomListItemText>  
          </ListItem>
          <ListItem button onClick={handlePrivateClick}>
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardRoundedIcon />
            </ListItemIcon>
            <CustomListItemText>Private Projects</CustomListItemText>  
          </ListItem>
          <ListItem button onClick={handleAboutClick}>
            <ListItemIcon className={classes.listItemIcon}>
          
              <MailIcon />
            </ListItemIcon>
            <CustomListItemText>About Me/Contact</CustomListItemText>  
          </ListItem>
          
        </List>
      </div>
    </Drawer>
  );
}
