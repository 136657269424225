import React, { useState, useEffect, useRef } from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import ResizeObserver from "resize-observer-polyfill";

const data = [
    {
      "taste": "Research",
      "Materialdesign": 0.4,
      "Productdesign": 0.25,
      "Conceptdesign": 0.275
    },
    {
      "taste": "Concept",
      "Materialdesign": 0.175,
      "Productdesign": 0.375,
      "Conceptdesign": 0.45
    },
    {
      "taste": "Iteration/Prototyping",
      "Materialdesign": 0.45,
      "Productdesign": 0.35,
      "Conceptdesign": 0.275
    },
   
    {
      "taste": "Prototype",
      "Materialdesign":0,
      "Productdesign": 0.3,
      "Conceptdesign": 0.45
    },
    
    {
      "taste": "Documentation",
      "Materialdesign": 0.45,
      "Productdesign": 0.35,
      "Conceptdesign": 0.35
    }
  ]
  const MyResponsiveRadar = ({ data }) => {
    const [isLegendVisible, setLegendVisible] = useState(true);
    const chartContainerRef = useRef();
  
    useEffect(() => {
      const ro = new ResizeObserver(entries => {
        const { width } = entries[0].contentRect;
        setLegendVisible(width > 400); 
      });
      if (chartContainerRef.current) {
        ro.observe(chartContainerRef.current);
      }
      return () => {
        if (chartContainerRef.current) {
          ro.unobserve(chartContainerRef.current);
        }
      };
    }, [chartContainerRef]);
  
    return (
      <div 
        ref={chartContainerRef}
        style={{
          position: "absolute", 
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <ResponsiveRadar
          data={data}
          keys={[ 'Materialdesign', 'Productdesign', 'Conceptdesign' ]}
          indexBy="taste"
          valueFormat=">-.2f"
          margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
        borderWidth={0}
        borderColor={{ from: 'color', modifiers: [] }}
        gridLabelOffset={36}
        dotSize={12}
        dotColor="#ffffff"
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color', modifiers: [] }}
        dotLabelYOffset={0}
        colors={{ scheme: 'reds' }}
        fillOpacity={0.5}
        motionConfig="wobbly"
          legends={isLegendVisible ? [
            {
              anchor: 'top-left',
                direction: 'column',
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: '#999',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                }
              ]
            }
          ] : []}
        />
      </div>
    );
  };
  
  export default function App() {
    return (
      <div style={{ width: 450, height: 450 }}>
        <MyResponsiveRadar data={data} />
      </div>
    );
  }