import { rgbToHex } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import React, { useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import TopBar from "./TopBar";
import Widget from "./widget";
import { Responsive, WidthProvider } from 'react-grid-layout';
const originalItems = ["a" , "b", "c", "d", "e", "f", "g", "h", "i", "j","k","l","m","n","o"];

const initialLayouts = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33, maxH: 2000 },
    { i: "b", x: 0, y: 900, w: 8, h: 87, minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 4, y: 200, w: 8, h: 95, minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 8, y: 1000, w: 4, h: 42, minW: 2, maxW: 12, minH: 30,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 9, h: 144, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 0, y: 100, w: 4, h: 109, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 8, h: 47, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 5, y: 600, w: 7, h: 50, minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 0, y: 800, w: 5, h: 50, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 8, y: 1200, w: 4, h: 32, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 9, y: 200, w: 3, h: 144, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 8, y: 10000, w: 4, h: 20, minW:2, minH: 15, maxW: 12,  maxH: 1600},
    { i: "n", x: 8, y: 500, w: 4, h: 47, minW:2, minH: 23, maxW: 12,  maxH: 1600},

  ]
};

const initialLayoutsPrivate = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33, maxH: 2000 },
    { i: "b", x: 8, y: 300, w: 4, h: 49, minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 100, w: 4, h: 67 ,minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 0, y: 0, w: 12, h: 42 ,minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 9, h: 144,minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 9, y: 0, w: 3, h: 144, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 4, y: 0, w: 4, h: 53, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 5, h: 27, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 4, y: 200, w: 4, h: 32, minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 0, y: 500, w: 12, h: 24, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 0, y: 300, w: 4, h: 32, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 0, y: 600, w: 12, h: 33, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 5, y: 1000, w: 3, h: 27, minW:2, minH: 23, maxW: 12,  maxH: 1600},
    { i: "n", x: 0, y: 0, w: 3, h: 27, minW:2, minH: 23, maxW: 12,  maxH: 1600}
  ]
};

const initialLayoutsMaterial= { 
  lg: [
    { i: "a", x: 0, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33,  maxH: 1600 },
    { i: "b", x: 8, y: 0, w: 4, h: 48, minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 200, w: 4, h: 56 , minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 4, y: 200, w: 8, h: 42 , minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 8, h: 58, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 4, y: 0, w: 4, h: 53, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 8, y: 0, w: 4, h: 56 ,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 4, y: 200, w: 4, h: 44, minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 8, y: 400, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 8, y: 300, w: 4, h: 32, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 0, y: 400, w: 12, h: 34, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 0, y: 500, w: 4, h: 40, minW:2, minH: 23, maxW: 12,  maxH: 1600},
    { i: "n", x: 5, y: 500, w: 3, h: 27, minW:2, minH: 23, maxW: 12,  maxH: 1600}

  ]
};



const initialLayoutsProduct = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33,  maxH: 1600 },
    { i: "b", x: 7, y: 0, w: 5, h: 59,  minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 0, w: 7, h: 85, minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 4, y: 200, w: 8, h: 42 , minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 8, h: 58, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 0, y: 100, w: 7, h: 49, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 4, h: 41 ,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 7, y: 0, w: 5, h: 37,  minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 8, y: 400, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 7, y: 300, w: 5, h: 38,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 4, y: 400, w: 8, h: 33, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 4, y: 500, w: 8, h: 40, minW:2, minH: 39, maxW: 12,  maxH: 1600},
    { i: "n", x: 0, y: 500, w: 4, h: 40, minW:2, minH: 39, maxW: 12,  maxH: 1600}

  ]
};


const initialLayoutsAbout = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33,  maxH: 1600 },
    { i: "b", x: 8, y: 0, w: 4, h: 48, minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 200, w: 4, h: 56 , minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 4, y: 200, w: 8, h: 42 , minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 8, h: 58, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 4, y: 0, w: 4, h: 53, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 4, h: 41 ,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 4, y: 200, w: 4, h: 44, minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 8, y: 400, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 8, y: 300, w: 4, h: 32, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 4, y: 400, w: 8, h: 33, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 4, y: 500, w: 8, h: 47, minW:2, minH: 39, maxW: 12,  maxH: 1600},
    { i: "n", x: 0, y: 0, w: 4, h: 47, minW:2, minH: 39, maxW: 12,  maxH: 1600},
    { i: "o", x: 0, y: 500, w: 12, h: 300, minW:2, minH: 39, maxW: 12,  maxH: 1600}
  ]
};

const initialLayoutsPrivacy = { 
  lg: [
    { i: "a", x: 4, y: 0, w: 8, h: 56, minW: 2, maxW: 12, minH: 33,  maxH: 1600 },
    { i: "b", x: 8, y: 0, w: 4, h: 48, minW: 2, maxW: 12, minH: 48,  maxH: 1600 },
    { i: "c", x: 0, y: 200, w: 4, h: 56 , minW: 2, maxW: 12, minH: 39,  maxH: 1600 },
    { i: "d", x: 4, y: 200, w: 8, h: 42 , minW: 2, maxW: 12, minH: 40,  maxH: 1600 },
    { i: "e", x: 0, y: 300, w: 8, h: 58, minW: 2, maxW: 12, minH: 39,  maxH: 1600  },
    { i: "f", x: 0, y: 0, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "g", x: 4, y: 0, w: 4, h: 53, minW: 2, maxW: 12, minH: 23,  maxH: 1600 },
    { i: "h", x: 0, y: 350, w: 4, h: 41 ,  minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "i", x: 4, y: 200, w: 4, h: 44, minW: 2, maxW: 12, minH: 30,  maxH: 1600},
    { i: "j", x: 8, y: 400, w: 4, h: 42, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "k", x: 8, y: 300, w: 4, h: 32, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "l", x: 4, y: 400, w: 8, h: 33, minW: 2, maxW: 12, minH: 23,  maxH: 1600},
    { i: "m", x: 4, y: 500, w: 8, h: 45, minW:2, minH: 39, maxW: 12,  maxH: 1600},
    { i: "n", x: 0, y: 500, w: 4, h: 45, minW:2, minH: 37, maxW: 12,  maxH: 1600},
    { i: "o", x: 0, y: 500, w: 12, h: 600, minW:2, minH: 39, maxW: 12,  maxH: 1600}
  ]
};

function Content({ size: { width }, activeWidgets, onRemoveItem, dragEnabled, toggleDrag }) {
  const [items, setItems] = useState(originalItems);
  const [layouts, setLayouts] = useState(
    getFromLS("layouts") || initialLayouts
  );

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  const getCurrentLayout = () => {
    return activeWidgets.reduce((acc, widget) => {
      let widgetLayout;
  
      // Determine which layout object to use based on the active widgets
      if (["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l","n"].every((item) => activeWidgets.includes(item))) {
        widgetLayout = initialLayouts.lg.find((layout) => layout.i === widget);
      } else if (["d", "e", "f", "j"].some((item) => activeWidgets.includes(item))) {
        widgetLayout = initialLayoutsPrivate.lg.find((layout) => layout.i === widget);
      } else if (["h","l","a"].some((item) => activeWidgets.includes(item))) {
        widgetLayout = initialLayoutsMaterial.lg.find((layout) => layout.i === widget);
      } else if (["b","c","g","i","k"].some((item) => activeWidgets.includes(item))) {
        widgetLayout = initialLayoutsProduct.lg.find((layout) => layout.i === widget);
      } else if (["m","n"].every((item) => activeWidgets.includes(item))) {
        widgetLayout = initialLayoutsAbout.lg.find((layout) => layout.i === widget);
      } else if (["o"].every((item) => activeWidgets.includes(item))) {
        widgetLayout = initialLayoutsPrivacy.lg.find((layout) => layout.i === widget);
      } // Add conditions for other layout objects as needed
      if (widgetLayout) {
        acc.push(widgetLayout);
      }
      return acc;
    }, []);
  };
  

  const onLayoutSave = () => {
    saveToLS("layouts", layouts);
  };


  const onAddItem = (itemId) => {
    setItems([...items, itemId]);

  };
  
  
  return (
    <>
      <TopBar
        onLayoutSave={onLayoutSave}
        items={items}
        onRemoveItem={onRemoveItem}
        onAddItem={onAddItem}
        originalItems={originalItems}
       // toggleDrag={toggleDrag}
      />
      

      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: getCurrentLayout() }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={3}
        width={width}
        onLayoutChange={onLayoutChange}
        draggableHandle={dragEnabled ? ".dragHandle" : ""}
        resizableHandle={dragEnabled ? ".resizeHandle" : ""}>
      {activeWidgets.map((key) => (

        <div
         key={key}
    className={`widget widget-${key}`} 
          data-grid={{ w: 0, h: 0, x: 0, y: Infinity }}
          static={!dragEnabled}
        >


        <Widget
            id={key}
            onRemoveItem={onRemoveItem}
            backgroundColor={
              key === 'a'
                ? '#ececec' 
                : key === 'b'
                ? '#ececec'
                : key === 'c'
                ? '#ececec' 
                : key === 'd'
                ? '#373737' 
                : key === 'e'
                ? '#373737' 
                : key === 'f'
                ? '#373737' 
                : key === 'g'
                ? '#ececec' 
                : key === 'h'
                ? '#ececec' 
                : key === 'i'
                ? '#ececec' 
                : key === 'j'
                ? '#373737' 
                : key === 'k'
                ? '#ececec' 
                : key === 'l'
                ? '#ececec'   
                : key === 'm'
                ? '#373737' 
                : '#373737' 
            } 


            deleteButtonColor={
              key === "a"
              ? '#373737' 
              : key === 'b'
              ? '#373737' 
              : key === 'c'
              ? '#373737' 
              : key === 'd'
              ? '#FFFFFF' 
              : key === 'e'
              ? '#FFFFFF' 
              : key === 'f'
              ? '#FFFFFF' 
              : key === 'g'
              ? '#373737' 
              : key === 'h'
              ? '#373737' 
              : key === 'i'
              ? '#373737' 
              : key === 'j'
              ? '#FFFFFF' 
              : key === 'k'
              ? '#373737' 
              : key === 'l'
              ? '#373737'
              : key === 'm'
              ? '#FFFFFF'
              : '#FFFFFF' 
            }
          
            infoButtonColor={
              key === "a"
              ? '#373737' 
              : key === 'b'
              ? '#373737' 
              : key === 'c'
              ? '#373737' 
              : key === 'd'
              ? '#FFFFFF' 
              : key === 'e'
              ? '#FFFFFF' 
              : key === 'f'
              ? '#FFFFFF' 
              : key === 'g'
              ? '#373737' 
              : key === 'h'
              ? '#373737' 
              : key === 'i'
              ? '#373737' 
              : key === 'j'
              ? '#FFFFFF' 
              : key === 'k'
              ? '#373737' 
              : key === 'l'
              ? '#373737'
              : key === 'm'
              ? '#FFFFFF'
              : '#FFFFFF' 
            }
          />
          <div className="dragHandle"></div>
          <div className="resizeHandle"></div>
          </div>
        ))}
      </ResponsiveGridLayout>
    </>
  );
}

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(Content);

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) { }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value
      })
    );
  }
}