import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import AddList from "./AddList";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import LockIcon from "@material-ui/icons/Lock";
const useStyles = makeStyles((theme) => ({
  appbar: {
    //marginLeft: theme.spacing(0.5),
    backgroundColor: "rgb(21,21,21)",
    color: theme.palette.text.secondary,
    [theme.breakpoints.up("sm")]: {
      zIndex: theme.zIndex.drawer + 1
    }
  },
  rightIcons: {
    marginLeft: theme.spacing(0.5)
  },
  spacer: {
    flexGrow: 1
  },
  spacer2: {
    paddingLeft: 35
  }
}));

export default function Header({
  dragEnabled,
  toggleDrag,
  handleDrawerToggle,
  onLayoutSave,
items,
onRemoveItem,
onAddItem,
originalItems,
activeWidgets,
  setActiveWidgets,
 toggleDarkMode,
 darkMode
}) {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appbar}>

  
    <Toolbar>
    
      <AddList
      items={activeWidgets}
      onRemoveItem={(itemId) => setActiveWidgets(activeWidgets.filter((i) => i !== itemId))}
        onAddItem={(itemId) => setActiveWidgets([...activeWidgets, itemId])}
        originalItems={originalItems}
      style={{ color: "#FFFFFF",position: "absolute"
       }}
      />
      <div className={classes.spacer2} />
      <IconButton
      style={{ color: "#FFFFFF" }}
      aria-label="toggle drag"
      onClick={toggleDrag}
          edge="start"
          className={classes.rightIcons}
        >
        {dragEnabled ? <DragIndicatorIcon /> : <LockIcon />}
        </IconButton>

      <div className={classes.spacer} />
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          style={{ color: "#FFFFFF" }}
        >
          <MenuIcon />
        </IconButton>

      </Toolbar>
    </AppBar>
  );
}



/*<IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDarkMode}
          edge="start"
          className={classes.rightIcons}
        >
          {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.rightIcons}
        >
          <Badge badgeContent={0} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          className={classes.rightIcons}
        >
          <AccountCircleIcon />
        </IconButton>
        
        
        The text below belongs behind the </Iconbutton>
        
        <Typography variant="h10" noWrap style={{ color: "white" }}>
        
          Medium
        </Typography>*/

