import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";


const gifs = [
  './images/BWLoop3.gif',
  //'./images/BWLoop2gifmin.gif',
];

const randomGif = gifs[Math.floor(Math.random() * gifs.length)];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    height: 400,
    display: "flex",
    justifyContent: "flex-start",
    backgroundImage: `url(${randomGif})`,
    backgroundSize: "cover",
    backgroundColor: "rgb(72, 72, 72);",
    backgroundPosition: "center",
    borderRadius: "0px", 
  },
  header: {
    Color: "#ff69b4",
    display: "flex",
    alignItems: "center",
    padding: "0.5rem",
    backgroundColor: "#F5F5F5"
  },
  spacer: {
    Color: "#ff69b4",
    flexGrow: 1,
    backgroundColor: "#F5F5F5"
  },
  body: {
    Color: "#ff69b4",
    padding: "0.5rem",
    flexGrow: 1,
    backgroundColor: "#F5F5F5"
  }
}));

export default function TopBar({
}) {
  const classes = useStyles();
  
  return (
    <Card className={classes.root}>
      
    </Card>
  );
}